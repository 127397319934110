<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Rechnungen
        </h6>

        <div class="element-box">
          <div class="alert alert-info" *ngIf="!invoices.length">Es sind keine Einträge vorhanden.</div>

          <table *ngIf="invoices.length" class="table table-striped">
            <thead>
              <tr>
                <th style="width: 10%;">Datum</th>
                <th style="width: 10%; white-space: nowrap;">Rechnungs-Nr.</th>
                <th style="width: 20%; white-space: nowrap;">Betrag</th>
                <th style="width: 15%;">Status</th>
                <th style="width: 45%;">PDF</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let invoice of invoices; let i = index">
                <td>{{ invoice.createdAt | date: 'dd.MM.yyyy' }}</td>
                <td>{{ invoice.id }}</td>
                <td style="white-space: nowrap;">
                  <strong>{{ invoice.brutto.replace('.', ',') }}&nbsp;&euro; inkl. MwSt.</strong><br />
                  <span style="color: #999;"
                    >({{ invoice.netto.replace('.', ',') }}&nbsp;&euro; zzgl.
                    {{ invoice.steuer.replace('.', ',') }}&nbsp;&euro; MwSt.)</span
                  >
                </td>
                <td style="white-space: nowrap;">
                  <ng-container *ngIf="invoice.zahlungsdatum">
                    <i class="fas fa-check" style="color: var(--primary); float: left; margin: 3px 7px 10px 3px;"></i>
                    {{ translateMethod(invoice.paymentMethod) }}<br />
                    {{ invoice.zahlungsdatum | date: 'dd.MM.yyyy' }}
                  </ng-container>
                  <ng-container *ngIf="!invoice.zahlungsdatum && invoice.paymentMethod != 'Storno'">
                    <i class="fas fa-times" style="color: var(--danger); float: left; margin: 3px 7px 10px 3px;"></i>
                    Offen
                    <!-- seit {{ invoice.zahlungsdatum | date: 'dd.MM.yyyy' }} --><br />
                    ({{ translateMethod(invoice.paymentMethod) }})
                  </ng-container>
                  <ng-container *ngIf="invoice.paymentMethod == 'Storno'">
                    <i class="fas fa-check" style="color: var(--warning); float: left; margin: 3px 7px 10px 3px;"></i>
                    {{ translateMethod(invoice.paymentMethod) }}<br />
                    {{ invoice.stornoDatum | date: 'dd.MM.yyyy' }}
                  </ng-container>
                </td>
                <td>
                  <button
                    class="btn"
                    [class.btn-light]="
                      invoice.mahnung1File || invoice.mahnung2File || invoice.paymentMethod == 'Storno'
                    "
                    [class.btn-primary]="
                      !invoice.mahnung1File && !invoice.mahnung2File && invoice.paymentMethod != 'Storno'
                    "
                    (click)="accountingService.downloadInvoice(invoice)"
                    target="_blank"
                  >
                    <i class="fas fa-file-download"></i>
                    &nbsp;Rechnung (PDF)
                  </button>
                  <ng-container *ngIf="invoice.mahnung1File">
                    &nbsp;<button
                      class="btn"
                      [class.btn-light]="invoice.mahnung2File"
                      [class.btn-primary]="!invoice.mahnung2File"
                      (click)="accountingService.downloadInvoice(invoice, 'mahnung1File', '1_Mahnung_')"
                      target="_blank"
                    >
                      <i class="fas fa-file-download"></i>
                      &nbsp;1. Mahnung (PDF)
                    </button>
                  </ng-container>
                  <ng-container *ngIf="invoice.mahnung2File">
                    &nbsp;<button
                      class="btn btn-primary"
                      (click)="accountingService.downloadInvoice(invoice, 'mahnung2File', '2_Mahnung_')"
                      target="_blank"
                    >
                      <i class="fas fa-file-download"></i>
                      &nbsp;2. Mahnung (PDF)
                    </button>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
