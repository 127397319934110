<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Allgemeine Einstellungen
        </h6>
        <div class="element-box">
          <form [formGroup]="generalSettingsForm" (ngSubmit)="saveSettings()">
            <div class="row">
              <div class="col-md-7 col-sm-12">
                Möchten Sie Ihre Gäste mit "Du" oder "Sie" ansprechen?
              </div>
              <div class="col-md-4 col-sm-12">
                <mat-select formControlName="pronominalForm" placeholder="Wählen Sie eine Option">
                  <mat-option *ngFor="let option of selectOptions" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-7 col-sm-12">
                Meta Pixel Code:
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="input-group">
                  <input class="form-control" formControlName="metaPixelCode" placeholder="Enter Meta Pixel Code" />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-7 col-sm-12">
                Google Pixel Code:
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="input-group">
                  <input class="form-control" formControlName="googlePixelCode" placeholder="Enter Google Pixel Code" />
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-12">
                <button
                  class="btn btn-primary btn-block btn-lg"
                  mat-raised-button
                  color="primary"
                  type="submit"
                  [disabled]="generalSettingsForm.invalid || loading"
                >
                  Einstellungen speichern
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
