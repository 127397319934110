<div [class.no-padding]="isFakeFullscreen">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper" [class.no-padding]="isFakeFullscreen">
        <ng-template [ngIf]="!isFakeFullscreen">
          <div class="element-actions" *ngIf="layout >= LAYOUT.SM" style="    margin-top: 8px;">
            <!-- <ng-select
                        class="custom"
                        appendTo="body"
                        [searchable]="false"
                        [clearable]="false"
                        (change)="fsChanged($event)"
                        placeholder="Textgröße"
                        >
                        <ng-option [value]="'18'">A <sup>+</sup></ng-option>
                        <ng-option [value]="'22'">A <sup>++</sup></ng-option>
                        <ng-option [value]="'14'">A <sup>-</sup></ng-option>
                        </ng-select> -->
            <button class="btn btn-white ml-10" (click)="downloadPdf()">
              <i class="fas fa-print fa-fw"></i>
              PDF Download
            </button>
            <!-- <button class="btn btn-white hidden-xs-down" (click)="openFullscreen()">
                        <i class="fas fa-expand-arrows-alt fa-fw"></i>
                        Im Fullscreen öffnen
                        </button> -->
          </div>
          <h6 class="element-header">
            <!-- Reservierungsbuch - Reservierungs-System -->
          </h6>
        </ng-template>

        <!-- <div
                    class="form-group button-container"
                    *ngIf="timeFilter && timeFilter.length > 0"
                    [class.no-bottom-margin]="isFullscreen"
                >
                    <button
                        *ngFor="let timeData of timeFilter; let i = index"
                        class="btn btn-white active filter-btn"
                        [class.selected]="checkSelectedFilter(timeData.name)"
                        (click)="filterReservationWithTime(timeData.name)"
                    >
                        {{ timeData.name }}
                    </button>
                </div> -->

        <div #fullscreenEl id="fullscreenElement" class="fullscreen-element" [class.is-fullscreen]="isFullscreen">
          <form [formGroup]="range" (ngSubmit)="getTransactionByYear()">
            <div class="row date-filter">
              <div class="col-md-3 col-sm-12">
                <div class="form-group" style="margin-right: 10px; width: 100%;">
                  <label for="">{{ 'Events&Tickets.Common.StartDate' | translate }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="startDateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker #startDateFromPicker (closed)="dateToInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateToInput
                      formControlName="startDate"
                      placeholder="Start Date"
                      type="text"
                      name="date"
                      [matDatepicker]="startDateFromPicker"
                      (focus)="startDateFromPicker.open()"
                      autocomplete="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="form-group" style="margin-right: 10px;  width: 100%;">
                  <label for="">{{ 'Events&Tickets.Common.EndDate' | translate }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="endDateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>

                    <mat-datepicker #endDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateFromInput
                      formControlName="endDate"
                      placeholder="End Date"
                      type="text"
                      name="date"
                      [matDatepicker]="endDateFromPicker"
                      (focus)="endDateFromPicker.open()"
                      autocomplete="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-1">
                <div class="btn-div">
                  <button class="btn btn-primary btn-lg" type="submit" [disabled]="!range.valid">
                    <i class="fas fa-search text-light"></i>
                  </button>
                </div>
              </div>
              <div class="col-4 col-md-1 pending-counter">
                <!-- <div class="pending-counter-btn">
                  <button
                    *ngIf="pendingCounter > 0"
                    class="btn btn-warning"
                    (click)="openAllPending()"
                    [matBadge]="pendingCounter"
                    matBadgePosition="after"
                    matBadgeColor="accent"
                    matTooltip="Offene Reservierungsanfragen"
                  >
                    <i class="fas fa-hourglass-half"></i>
                  </button>
                </div> -->
              </div>
              <div class="col-4 col-md-4 add-new-res">
                <div class="add-btn">
                  <!--  -->
                  <div *ngIf="layout > LAYOUT.XS && !isFullscreen" class="column">
                    <button
                      class="btn"
                      [class.btn-white]="!editMode && !isFullscreen"
                      [class.btn-dark]="!editMode && isFullscreen"
                      [class.btn-warning]="editMode"
                      (click)="toggleEditMode()"
                      *ngIf="
                        (deviceService?.isMobile() || deviceService?.isTablet()) &&
                        (view === 'timeline' || view === 'tableplan')
                      "
                    >
                      <i class="fas fa-hand-point-up fa-fw"></i>
                    </button>
                    <button
                      class="btn btn-primary"
                      (click)="addReservation()"
                      *ngxPermissionsOnly="['admin', 'addReservation']"
                    >
                      <i class="fas fa-plus-circle fa-fw"></i> Neue Reservierung
                    </button>
                    <button
                      class="btn btn-margin"
                      [class.btn-white]="!isFullscreen"
                      [class.btn-dark]="isFullscreen"
                      (click)="addReservation(true)"
                      *ngxPermissionsOnly="['admin', 'addReservation']"
                    >
                      <i class="fas fa-plus-square fa-fw"></i> <span *ngIf="layout > LAYOUT.LG">Walk-In</span>
                    </button>
                    <button class="btn btn-danger btn-margin" (click)="closeFullscreen()" *ngIf="isFullscreen">
                      <i class="fas fa-times fa-fw"></i>
                    </button>
                  </div>

                  <div *ngIf="layout < LAYOUT.SM || isFullscreen" class="column">
                    <button
                      class="btn"
                      [class.btn-white]="!editMode && !isFullscreen"
                      [class.btn-dark]="!editMode && isFullscreen"
                      [class.btn-warning]="editMode"
                      (click)="toggleEditMode()"
                      *ngIf="
                        (deviceService?.isMobile() || deviceService?.isTablet()) &&
                        (view === 'timeline' || view === 'tableplan')
                      "
                    >
                      <i class="fas fa-hand-point-up fa-fw"></i>
                    </button>
                    <button class="btn btn-primary btn-margin" (click)="openCreateBottomSheet()">
                      <i class="fas fa-plus-circle fa-fw"></i>
                    </button>
                    <button class="btn btn-danger btn-margin" (click)="closeFullscreen()" *ngIf="isFullscreen">
                      <i class="fas fa-times fa-fw"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="row" style="margin-top: 1.5rem; margin-bottom: 1.5rem">
            <div class="form-group col-md-2 col-sm-12 label-filter">
              <label class="title-filter" for=""> In Ergebnissen suchen </label
              ><input
                class="form-control"
                placeholder="Geben Sie einen Gastnamen ein"
                type="text"
                [(ngModel)]="filter.name"
                [class.filtered]="filter.name"
                (keyup)="searchReservation($event)"
              />
            </div>
            <div class="form-group col-md-2 col-sm-12 label-filter">
              <label class="title-filter" for=""> Nach Status filtern </label>
              <ng-select
                [searchable]="false"
                [clearable]="true"
                [(ngModel)]="filter.status"
                (change)="searchReservation($event)"
                placeholder="-- Status auswählen --"
                [class.filtered]="filter.status"
                [items]="allStatus"
                bindLabel="label"
                bindValue="value"
                groupBy="type"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span [class]="item.icon"></span>
                  {{ item.label }}
                </ng-template>
                <ng-template ng-optgroup-tmp let-item="item" let-index="index">
                  <span *ngIf="item.type === 'single'">Einzelne</span>
                  <span *ngIf="item.type === 'group'">Gruppiert</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <span [class]="item.icon"></span>
                  {{ item.label }}
                  <small *ngIf="item.description" style="display:block">{{ item.description }}</small>
                </ng-template>
              </ng-select>
            </div>
            <div class="form-group col-md-2 col-sm-12 label-filter">
              <label class="title-filter" for=""> Nach Raum filtern </label>
              <ng-select
                [searchable]="false"
                [clearable]="true"
                multiple="true"
                [(ngModel)]="filter.roomId"
                (change)="searchReservation($event)"
                placeholder="-- Raum auswählen --"
                [class.filtered]="filter.roomId"
              >
                <ng-option [value]="room.id" *ngFor="let room of rooms">{{ room.name }}</ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-3 col-sm-12 label-filter">
              <label class="title-filter" for=""> Nach Tags von heute filtern </label>
              <!-- [searchable]="!deviceService?.isMobile() && !deviceService?.isTablet()" -->
              <ng-select
                [searchable]="false"
                [items]="tags"
                [hideSelected]="true"
                multiple="true"
                bindLabel="label"
                (change)="searchReservation($event)"
                placeholder="-- Tags auswählen --"
                [(ngModel)]="filter.tags"
                [class.filtered]="filter.tags && filter.tags.length"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">
                    <i class="fas fa-circle" [style.color]="item.color"></i>
                    {{ item.label }}
                  </span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                  <i class="fas fa-circle" [style.color]="item.color" *ngIf="item.color"></i> {{ item.label }}
                </ng-template>
              </ng-select>
            </div>
            <div class="form-group col-md-3 col-sm-12 label-filter">
              <label class="title-filter" for=""> Nach Schicht filtern </label>
              <ng-select
                [searchable]="false"
                [clearable]="true"
                multiple="true"
                [(ngModel)]="filter.shiftId"
                (change)="searchReservation($event)"
                placeholder="-- Schicht auswählen --"
                [class.filtered]="filter.shiftId"
              >
                <ng-option [value]="shift.id" *ngFor="let shift of shifts">{{ shift.name }}</ng-option>
              </ng-select>
            </div>
          </div>
          <!-- <div class="row">
                        <div class="col-md-5 col-sm-12">
                            <form [formGroup]="range" (ngSubmit)="getTransactionByYear()">
                              <div class="row">
                                <div class="col-md-5 col-sm-12">
                                  <div class="form-group">
                                    <label class="title-filter" for="">{{ 'Events&Tickets.Common.StartDate' | translate }}</label>
                                    <div class="input-group">
                                      <div class="input-group-prepend" (click)="startDateFromPicker.open()">
                                        <div class="input-group-text">
                                          <i class="fas fa-calendar-alt"></i>
                                        </div>
                                      </div>
                                      <mat-datepicker #startDateFromPicker (closed)="dateToInput.blur()"></mat-datepicker>
                                      <input
                                        class="form-control"
                                        #dateToInput
                                        formControlName="startDate"
                                        placeholder="Start Date"
                                        type="text"
                                        name="date"
                                        [matDatepicker]="startDateFromPicker"
                                        (focus)="startDateFromPicker.open()"
                                        autocomplete="false"
                                      />
                                    </div>
                                  </div>
                                </div>
                  
                                <div class="col-md-5 col-sm-12">
                                  <div class="form-group">
                                    <label for="">{{ 'Events&Tickets.Common.EndDate' | translate }}</label>
                                    <div class="input-group">
                                      <div class="input-group-prepend" (click)="endDateFromPicker.open()">
                                        <div class="input-group-text">
                                          <i class="fas fa-calendar-alt"></i>
                                        </div>
                                      </div>
  
                                      <mat-datepicker #endDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                                      <input
                                        class="form-control"
                                        #dateFromInput
                                        formControlName="endDate"
                                        placeholder="End Date"
                                        type="text"
                                        name="date"
                                        [matDatepicker]="endDateFromPicker"
                                        (focus)="endDateFromPicker.open()"
                                        autocomplete="false"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-2 col-sm-12 text-center vertical-center search-btn">
                                  <button class="btn btn-primary btn-lg" type="submit" [disabled]="!range.valid">
                                    <i class="fas fa-search text-light"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                        </div>
                        <div class="col-md-1 col-sm-12">
                            <button
                            *ngIf="pendingCounter > 0"
                                class="btn btn-warning"
                                (click)="openAllPending()"
                                [matBadge]="pendingCounter"
                                matBadgePosition="after"
                                matBadgeColor="accent"
                                matTooltip="Offene Reservierungsanfragen"
                            >
                                <i class="fas fa-hourglass-half"></i>
                            </button>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div
                                *ngIf="layout > LAYOUT.XS && !isFullscreen"
                                class="column"
                                fxFlex
                                fxFlexOrder="1"
                                fxFlexOrder.gt-xs="2"
                                fxLayoutGap="5px"
                                style="text-align: right;"
                            >
                                <button
                                    class="btn"
                                    [class.btn-white]="!editMode && !isFullscreen"
                                    [class.btn-dark]="!editMode && isFullscreen"
                                    [class.btn-warning]="editMode"
                                    (click)="toggleEditMode()"
                                    *ngIf="
                                    (deviceService?.isMobile() || deviceService?.isTablet()) &&
                                    (view === 'timeline' || view === 'tableplan')
                                    "
                                >
                                    <i class="fas fa-hand-point-up fa-fw"></i>
                                </button>
                                <button
                                    class="btn btn-primary"
                                    (click)="addReservation()"
                                    *ngxPermissionsOnly="['admin', 'addReservation']"
                                >
                                    <i class="fas fa-plus-circle fa-fw"></i> Neue Reservierung
                                </button>
                                <button
                                    class="btn btn-margin"
                                    [class.btn-white]="!isFullscreen"
                                    [class.btn-dark]="isFullscreen"
                                    (click)="addReservation(true)"
                                    *ngxPermissionsOnly="['admin', 'addReservation']"
                                >
                                <i class="fas fa-plus-square fa-fw"></i> <span *ngIf="layout > LAYOUT.LG">Walk-In</span>
                                </button>
                                <button class="btn btn-danger btn-margin" (click)="closeFullscreen()" *ngIf="isFullscreen">
                                    <i class="fas fa-times fa-fw"></i>
                                </button>
                            </div>
                        </div>
                        
                    </div> -->
          <!-- <div class="button-bar" #buttonBar fxLayout="row">
                        <div class="btn-group column" fxFlexOrder="-1" fxFlex="initial" style="margin-right: 10px">
                            <div
                                class="btn-group btn-group-toggle"
                                ngbRadioGroup
                                name="radioBasic"
                                [(ngModel)]="view"
                                (ngModelChange)="changeView($event)"
                            >
                                <label ngbButtonLabel [class.btn-dark]="isFullscreen" [class.btn-white]="!isFullscreen">
                                <input ngbButton type="radio" value="list" />
                                <i class="fas fa-list-ul"></i>
                                <ng-template [ngIf]="layout >= LAYOUT.SM || layout < LAYOUT.XS">Liste</ng-template>
                                </label>
                                <label ngbButtonLabel [class.btn-white]="!isFullscreen" [class.btn-dark]="isFullscreen">
                                <input ngbButton type="radio" value="timeline" />
                                <i class="fas fa-calendar-week"></i>
                                <ng-template [ngIf]="layout >= LAYOUT.SM || layout < LAYOUT.XS">Zeitleiste</ng-template>
                                </label>
                                <label
                                *ngIf="bookSettings?.showTablePlan"
                                ngbButtonLabel
                                [class.btn-white]="!isFullscreen"
                                [class.btn-dark]="isFullscreen"
                                >
                                <input ngbButton type="radio" value="tableplan" />
                                <i class="fas fa-table"></i>
                                <ng-template [ngIf]="layout >= LAYOUT.SM || layout < LAYOUT.XS">
                                    {{ 'tablePlan.tablePlan' | translate }}
                                </ng-template>
                                </label>
                            </div>
                        </div>

                        <div
                            class="column date-selection"
                            fxFlex
                            fxFlexOrder="2"
                            fxFlexOrder.gt-xs="1"
                            fxFlexGrow="2"
                            fxLayoutGap="10px"
                        >
                            <button class="btn" [class.btn-white]="!isFullscreen" [class.btn-dark]="isFullscreen" (click)="today()">
                                <i class="fas fa-home"></i>
                            </button>
                            <div class="btn-group">
                                <button
                                    class="btn btn-margin"
                                    [class.btn-white]="!isFullscreen"
                                    [class.btn-dark]="isFullscreen"
                                    (click)="prevDay()"
                                >
                                    <i class="fas fa-chevron-left"></i>
                                </button>
                                <input
                                    matInput
                                    [matDatepicker]="picker"
                                    type="text"
                                    [(ngModel)]="currentDate"
                                    (dateChange)="changeDate()"
                                />
                                <button
                                    class="btn date-btn"
                                    [class.btn-white]="!isFullscreen"
                                    [class.btn-dark]="isFullscreen"
                                    (click)="picker.open()"
                                >
                                    <ng-template [ngIf]="isToday()">Heute</ng-template>
                                    <ng-template [ngIf]="!isToday() && layout >= LAYOUT.MD">{{
                                        currentDate | dfnsFormat: 'dddd, DD. MMMM YYYY'
                                    }}</ng-template>
                                    <ng-template [ngIf]="!isToday() && layout < LAYOUT.MD">{{
                                        currentDate | dfnsFormat: 'dd, DD. MMM YYYY'
                                    }}</ng-template>
                                </button>
                                <mat-datepicker #picker></mat-datepicker>
                                <button
                                    class="btn"
                                    [class.btn-white]="!isFullscreen"
                                    [class.btn-dark]="isFullscreen"
                                    (click)="nextDay()"
                                >
                                    <i class="fas fa-chevron-right"></i>
                                </button>
                            </div>
                            <div style="    width: 100%;" >
                                <form [formGroup]="range" (ngSubmit)="getTransactionByYear()" style="    display: flex;">
                                    <div class="form-group" style="    margin-right: 10px;">
                                        <label for="">{{ 'Events&Tickets.Common.StartDate' | translate }}</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend" (click)="startDateFromPicker.open()">
                                                <div class="input-group-text">
                                                    <i class="fas fa-calendar-alt"></i>
                                                </div>
                                            </div>
                                            <mat-datepicker #startDateFromPicker (closed)="dateToInput.blur()"></mat-datepicker>
                                            <input
                                                class="form-control"
                                                #dateToInput
                                                formControlName="startDate"
                                                placeholder="Start Date"
                                                type="text"
                                                name="date"
                                                [matDatepicker]="startDateFromPicker"
                                                (focus)="startDateFromPicker.open()"
                                                autocomplete="false"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="">{{ 'Events&Tickets.Common.EndDate' | translate }}</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend" (click)="endDateFromPicker.open()">
                                                <div class="input-group-text">
                                                    <i class="fas fa-calendar-alt"></i>
                                                </div>
                                            </div>
      
                                            <mat-datepicker #endDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                                            <input
                                                class="form-control"
                                                #dateFromInput
                                                formControlName="endDate"
                                                placeholder="End Date"
                                                type="text"
                                                name="date"
                                                [matDatepicker]="endDateFromPicker"
                                                (focus)="endDateFromPicker.open()"
                                                autocomplete="false"
                                            />
                                        </div>
                                    </div>
                                    <div class="text-center vertical-center search-btn">
                                        <button class="btn btn-primary btn-lg" type="submit" [disabled]="!range.valid">
                                          <i class="fas fa-search text-light"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="text-center vertical-center search-btn">
                                <button
                                    *ngIf="pendingCounter > 0"
                                    class="btn btn-warning"
                                    (click)="openAllPending()"
                                    [matBadge]="pendingCounter"
                                    matBadgePosition="after"
                                    matBadgeColor="accent"
                                    matTooltip="Offene Reservierungsanfragen"
                                >
                                    <i class="fas fa-hourglass-half"></i>
                                </button>
                            </div>
                        </div>

                        <div
                            *ngIf="layout > LAYOUT.XS && !isFullscreen"
                            class="column"
                            fxFlex
                            fxFlexOrder="1"
                            fxFlexOrder.gt-xs="2"
                            fxLayoutGap="5px"
                            style="text-align: right;"
                        >
                            <button
                                class="btn text-center vertical-center search-btn"
                                [class.btn-white]="!editMode && !isFullscreen"
                                [class.btn-dark]="!editMode && isFullscreen"
                                [class.btn-warning]="editMode"
                                (click)="toggleEditMode()"
                                *ngIf="
                                (deviceService?.isMobile() || deviceService?.isTablet()) &&
                                (view === 'timeline' || view === 'tableplan')
                                "
                            >
                                <i class="fas fa-hand-point-up fa-fw"></i>
                            </button>
                            <button
                                class="btn btn-primary text-center vertical-center search-btn"
                                (click)="addReservation()"
                                *ngxPermissionsOnly="['admin', 'addReservation']"
                            >
                                <i class="fas fa-plus-circle fa-fw"></i> Neue Reservierung
                            </button>
                            <button
                                class="btn btn-margin text-center vertical-center search-btn"
                                [class.btn-white]="!isFullscreen"
                                [class.btn-dark]="isFullscreen"
                                (click)="addReservation(true)"
                                *ngxPermissionsOnly="['admin', 'addReservation']"
                            >
                                <i class="fas fa-plus-square fa-fw"></i> <span *ngIf="layout > LAYOUT.LG">Walk-In</span>
                            </button>
                            <button class="btn btn-danger btn-margin text-center vertical-center search-btn" (click)="closeFullscreen()" *ngIf="isFullscreen">
                                <i class="fas fa-times fa-fw"></i>
                            </button>
                        </div>

                        <div
                        *ngIf="layout < LAYOUT.SM || isFullscreen"
                        class="column"
                        fxFlexOrder="1"
                        fxFlexOrder.gt-xs="2"
                        fxLayoutGap="5px"
                        style="text-align: right;"
                        >
                        <button
                            class="btn"
                            [class.btn-white]="!editMode && !isFullscreen"
                            [class.btn-dark]="!editMode && isFullscreen"
                            [class.btn-warning]="editMode"
                            (click)="toggleEditMode()"
                            *ngIf="
                            (deviceService?.isMobile() || deviceService?.isTablet()) &&
                            (view === 'timeline' || view === 'tableplan')
                            "
                        >
                            <i class="fas fa-hand-point-up fa-fw"></i>
                        </button>
                        <button class="btn btn-primary btn-margin" (click)="openCreateBottomSheet()">
                            <i class="fas fa-plus-circle fa-fw"></i>
                        </button>
                        <button class="btn btn-danger btn-margin" (click)="closeFullscreen()" *ngIf="isFullscreen">
                            <i class="fas fa-times fa-fw"></i>
                        </button>
                        </div>
                    </div> -->

          <ng-scrollbar
            [trackX]="false"
            [class.no-scrollbar]="!isFullscreen"
            [shown]="!isFullscreen ? 'native' : 'native'"
            [compact]="true"
            *ngIf="view === 'list'"
          >
            <div [style.height]="isFullscreen ? listHeight : 'initial'">
              <!-- <div class="filter-box" fxLayout="row">
                            <div class="form-group" fxFlex="50" fxFlex.gt-xs="20" style="padding-right: 10px;">
                            <label for=""> In Ergebnissen suchen </label
                            ><input
                                class="form-control"
                                placeholder="Geben Sie einen Gastnamen ein"
                                type="text"
                                [(ngModel)]="filter.name"
                                [class.filtered]="filter.name"
                                (keyup)="searchReservation($event)"
                            />
                            </div>
                            <div class="form-group" fxFlex="50" fxFlex.gt-xs="20">
                            <div [style.padding-right]="layout > LAYOUT.XXS ? '10px' : ''">
                                <label for=""> Nach Status filtern </label>
                                <ng-select
                                [searchable]="false"
                                [clearable]="true"
                                [(ngModel)]="filter.status"
                                (change)="searchReservation($event)"
                                placeholder="-- Status auswählen --"
                                [class.filtered]="filter.status"
                                [items]="allStatus"
                                bindLabel="label"
                                bindValue="value"
                                groupBy="type"
                                >
                                <ng-template ng-label-tmp let-item="item">
                                    <span [class]="item.icon"></span>
                                    {{ item.label }}
                                </ng-template>
                                <ng-template ng-optgroup-tmp let-item="item" let-index="index">
                                    <span *ngIf="item.type === 'single'">Einzelne</span>
                                    <span *ngIf="item.type === 'group'">Gruppiert</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    <span [class]="item.icon"></span>
                                    {{ item.label }}
                                    <small *ngIf="item.description" style="display:block">{{ item.description }}</small>
                                </ng-template>
                                </ng-select>
                            </div>
                            </div>
                            <div class="form-group" fxFlex="50" fxFlex.gt-xs="20" style="padding-right: 10px;">
                            <label for=""> Nach Raum filtern </label>
                            <ng-select
                                [searchable]="false"
                                [clearable]="true"
                                [(ngModel)]="filter.roomId"
                                (change)="searchReservation($event)"
                                placeholder="-- Raum auswählen --"
                                [class.filtered]="filter.roomId"
                            >
                                <ng-option [value]="room.id" *ngFor="let room of rooms">{{ room.name }}</ng-option>
                            </ng-select>
                            </div>
                            <div class="form-group" fxFlex="50" fxFlex.gt-xs="20">
                            <label for=""> Nach Tags von heute filtern </label>
                            <ng-select
                                [searchable]="!deviceService?.isMobile() && !deviceService?.isTablet()"
                                [items]="tags"
                                [hideSelected]="true"
                                multiple="true"
                                bindLabel="label"
                                (change)="searchReservation($event)"
                                placeholder="-- Tags auswählen --"
                                [(ngModel)]="filter.tags"
                                [class.filtered]="filter.tags && filter.tags.length"
                            >
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label">
                                    <i class="fas fa-circle" [style.color]="item.color"></i>
                                    {{ item.label }}
                                </span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item">
                                <i class="fas fa-circle" [style.color]="item.color" *ngIf="item.color"></i> {{ item.label }}
                                </ng-template>
                            </ng-select>
                            </div>
                            <div
                            class="form-group"
                            fxFlex="50"
                            fxFlex.gt-xs="20"
                            style="padding-left: 10px;"
                            *ngIf="bookSettings && bookSettings.includeShift > 0"
                            >
                            <label for=""> Nach Schicht filtern </label>
                            <ng-select
                                [searchable]="false"
                                [clearable]="true"
                                [(ngModel)]="filter.shiftId"
                                (change)="searchReservation($event)"
                                placeholder="-- Schicht auswählen --"
                                [class.filtered]="filter.shiftId"
                            >
                                <ng-option [value]="shift.id" *ngFor="let shift of shifts">{{ shift.name }}</ng-option>
                            </ng-select>
                            </div>
                        </div> -->
              <!-- <app-occupancy-per-hour
                            *ngIf="bookSettings?.showOccupancy"
                            [listOfReservations]="reservations"
                        ></app-occupancy-per-hour> -->
              <p *ngFor="let alert of alerts">
                <ngb-alert type="warning" (close)="closeAlert(alert)" *ngIf="alert.type !== 'guest'"
                  ><strong>Hinweis:</strong>
                  <a *ngIf="alert.fileUrl" href="https://cdn.gastroguide.de/{{ alert.fileUrl }}" class="pdf-files"
                    ><i class="fas fa-file-download file-url-book"></i>
                  </a>
                  {{ alert.info }}
                </ngb-alert>
              </p>
              <p *ngFor="let alert of resMenuOrderAlert">
                <ngb-alert type="info" (close)="closeResMenuOrderAlert(alert)">{{ alert.info }} </ngb-alert>
              </p>
              <div class="element-box list-view" *ngIf="view === 'list'">
                <ng-container *ngIf="reservations.length">
                  <app-reservations-list
                    [(reservations)]="reservations"
                    [editing]="editing"
                    [isArchiveList]="isArchive"
                    [loading]="loadingReservationList"
                    [date]="currentDate"
                    [startDate]="this.selectedStartDate"
                    [endDate]="this.selectedEndDate"
                    [isFullscreen]="isFullscreen"
                    [resetTimeFilter]="resetTimeFilter"
                    [showListOfReservation]="true"
                    [reservationListActive]="reservationListActive"
                    [allStatus]="allStatus"
                    [resStatusList]="resStatusList"
                    (reservationsFiltered)="reservationsFiltered($event)"
                  ></app-reservations-list>
                </ng-container>
              </div>
            </div>
          </ng-scrollbar>

          <div class="clearfix" *ngIf="view === 'timeline'">
            <mat-tab-group
              animationDuration="0ms"
              (selectedTabChange)="selectedTabChange($event)"
              [selectedIndex]="tab"
              #roomTabsGroup
            >
              <mat-tab *ngFor="let room of rooms">
                <ng-template mat-tab-label>
                  <i class="fas fa-lock fa-fw" *ngIf="!room.isActive" style="padding-right: 15px;"></i> {{ room.name }}
                </ng-template>
              </mat-tab>
            </mat-tab-group>
            <div class="element-box clearfix reservation-timeline-box">
              <div
                class="alert alert-warning borderless"
                style="margin-bottom:0; margin: 10px"
                *ngIf="!tablesData.length"
              >
                <h5 class="alert-heading">Keine Tische gefunden</h5>
                <p>
                  Sie haben noch keine Tische in diesem Raum angelegt. Um die Zeitleisten-Ansicht nutzen zu können,
                  benötigen Sie Tische.
                  <br />Erstellen Sie Tische in den Reservierungs-Einstellungen.
                </p>
                <a
                  class="btn btn-primary"
                  routerLink="/client/{{ activeClient?.id }}/reservation/settings"
                  routerLinkActive="active"
                  [queryParams]="{ tab: 1 }"
                >
                  Tische erstellen
                </a>
              </div>
              <dx-scheduler
                *ngIf="dataSource && tablesData.length && bookSettings"
                [dataSource]="dataSource"
                [views]="[
                  { type: 'timelineDay', name: 'Tagesansicht' },
                  { type: 'timelineWeek', name: 'Wochenansicht' }
                ]"
                [height]="isFullscreen ? schedulerHeight : ''"
                currentView="timelineDay"
                [firstDayOfWeek]="0"
                [startDayHour]="bookSettings.startTime"
                [endDayHour]="bookSettings.endTime"
                [cellDuration]="bookSettings && bookSettings.timeInterval ? bookSettings.timeInterval : 30"
                [groups]="['tableId']"
                [currentDate]="currentDate"
                appointmentTemplate="reservation-template"
                resourceCellTemplate="resourceCellTemplate"
                timeCellTemplate="timeCellTemplate"
                dateCellTemplate="dateCellTemplate"
                appointmentCollectorTemplate="appointmentCollectorTemplate"
                [shadeUntilCurrentTime]="true"
                (onInitialized)="onInitialized($event)"
                (onContentReady)="onContentReady($event)"
                (onAppointmentRendered)="onAppointmentRendered($event)"
                (onAppointmentUpdating)="onAppointmentUpdating($event)"
                (onAppointmentClick)="onAppointmentClick($event)"
                (onCellClick)="onCellClick($event)"
                (onCellContextMenu)="onCellContextMenu($event)"
                (onAppointmentContextMenu)="onAppointmentContextMenu($event)"
                [showCurrentTimeIndicator]="true"
                maxAppointmentsPerCell="unlimited"
                crossScrollingEnabled="true"
                class="reservations-scheduler"
              >
                <dxo-editing
                  #editingOptions
                  [allowAdding]="false"
                  [allowUpdating]="true"
                  [allowDeleting]="true"
                  [allowResizing]="true"
                  [allowDragging]="(!deviceService?.isMobile() && !deviceService?.isTablet()) || editMode"
                ></dxo-editing>
                <div *dxTemplate="let data of 'appointmentCollectorTemplate'" class="collector">
                  <div>{{ data.appointmentCount }}</div>
                </div>
                <div *dxTemplate="let table of 'resourceCellTemplate'">
                  <div class="category-name">
                    <span class="badge badge-default" *ngIf="!table.data.isCombined">
                      {{ table.data.seats }}
                    </span>
                    <ng-template [ngIf]="!table.data.isCombined">{{ table.data.name }}</ng-template>
                    <ng-template [ngIf]="table.data.isCombined">Kombination {{ table.data.name }}</ng-template>

                    <ng-template [ngIf]="table.data.isCombined">
                      <small *ngFor="let comTable of table.data.combinedTables">#{{ comTable.name }} </small>
                    </ng-template>
                  </div>
                </div>
                <div
                  *dxTemplate="let reservation of 'reservation-template'"
                  class="status-{{ reservation.status }}"
                  [class.is-editing]="editMode"
                >
                  <div>
                    <div>
                      <span class="badge status-badge">
                        {{ reservation.peopleCount }}
                      </span>
                      <strong class="d-inline" *ngIf="reservation.guestData"
                        >{{ reservation.guestData.name }}
                        <i
                          class="fa fa-info-circle guest-note-table-plan"
                          *ngIf="reservation.msg"
                          [matTooltip]="reservation.msg"
                        ></i>
                        <i
                          class="fa fa-info-circle admin-note-table-plan "
                          *ngIf="reservation.notes"
                          [matTooltip]="reservation.notes"
                        ></i>
                      </strong>
                      <strong class="d-inline" *ngIf="!reservation.guestData">Walk-In</strong>
                    </div>
                    <div class="dropdown-appointment-dates" *ngIf="false">
                      {{
                        (reservation.startDate | date: 'shortTime') + ' - ' + (reservation.endDate | date: 'shortTime')
                      }}
                      Uhr
                    </div>
                    <i
                      class="fas fa-puzzle-piece combined-icon"
                      matTooltip="Tischkombination"
                      *ngIf="reservation.combinedColor"
                      [style.color]="reservation.combinedColor"
                    ></i>
                  </div>
                </div>
                <dxi-resource
                  fieldExpr="ownerId"
                  [allowMultiple]="true"
                  [dataSource]="resourcesData"
                  label="Owner"
                  [useColorAsDefault]="true"
                ></dxi-resource>
                <dxi-resource
                  fieldExpr="tableId"
                  [allowMultiple]="false"
                  [dataSource]="tablesData"
                  label="Tisch"
                ></dxi-resource>
                <div *dxTemplate="let data of 'timeCellTemplate'">
                  <div [class.disabled]="isIntermediateTime(data.text)">{{ data.text }}</div>
                </div>
                <div *dxTemplate="let data of 'dateCellTemplate'">
                  <div>{{ data.date | dfnsFormat: 'dddd, DD. MMMM YYYY' }}</div>
                </div>
              </dx-scheduler>
              <dx-context-menu
                #reservationMenu
                [dataSource]="reservationContextMenuItems"
                target=".dx-scheduler-appointment"
                (onItemClick)="onContextMenuItemClick($event)"
                [width]="200"
              >
                <div *dxTemplate="let e of 'item'">
                  <div *ngIf="e.color">
                    <div class="item-badge" [ngStyle]="{ backgroundColor: e.color }"></div>
                  </div>
                  <div class="item-text">{{ e.text }}</div>
                </div>
              </dx-context-menu>

              <dx-context-menu
                #cellMenu
                [dataSource]="cellContextMenuItems"
                target=".dx-scheduler-date-table-cell"
                (onItemClick)="onContextMenuItemClick($event)"
                (onShown)="onReservationContextMenuShowing($event)"
                [width]="200"
              >
              </dx-context-menu>
            </div>
          </div>
          <div class="element-box clearfix reservation-timeline-box" *ngIf="view === 'tableplan'">
            <div
              class="alert alert-warning borderless"
              style="margin-bottom:0; margin: 10px"
              *ngIf="!tablesData.length"
            >
              <h5 class="alert-heading">Keine Tische gefunden</h5>
              <p>
                Sie haben noch keine Tische angelegt. Um die Tischplan-Ansicht zu verwenden, benötigen Sie Tische.
                <br />Erstellen Sie Tische in den Reservierungs-Einstellungen.
              </p>
              <a
                class="btn btn-primary"
                routerLink="/client/{{ activeClient?.id }}/reservation/settings"
                routerLinkActive="active"
                [queryParams]="{ tab: 1 }"
              >
                Tische erstellen
              </a>
            </div>
            <app-table-plan
              *ngIf="tablesData.length > 0"
              [isEditing]="false"
              [date]="currentDate"
              [reservations]="reservations"
              [allowDragging]="editMode"
              [isFullscreen]="isFullscreen"
              [reservationBookSettings]="bookSettings"
            ></app-table-plan>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
