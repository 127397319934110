<breadcrumb></breadcrumb>
<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabClick($event)">
          <mat-tab label="Logfiles">
            <h6>
              <!-- Logfiles -->
              <small *ngIf="filterBy"><br />Filter: <a class="link" (click)="filterOrdersBy()">aufheben</a></small>
            </h6>

            <div class="element-box mat-wrapper">
              <div class="row">
                <div class="col-md-3">
                  <section>
                    <div class="form-group">
                      <label for="">Datum von</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateFromInput
                          placeholder=""
                          [(ngModel)]="dates.from"
                          type="text"
                          name="date"
                          [matDatepicker]="dateFromPicker"
                          (focus)="dateFromPicker.open()"
                          (dateChange)="getTransactions(false)"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-md-3">
                  <section>
                    <div class="form-group">
                      <label for="">Datum bis</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateToInput
                          placeholder=""
                          [(ngModel)]="dates.to"
                          type="text"
                          name="date"
                          [matDatepicker]="dateToPicker"
                          (focus)="dateToPicker.open()"
                          (dateChange)="getTransactions(false)"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-md-3" *ngIf="tablePages.length > 1">
                  <section>
                    <div class="form-group">
                      <label for="">Zeige Seite</label>
                      <div class="input-group">
                        <mat-select [(ngModel)]="tableOffset" (selectionChange)="changeTableOffset(tableOffset - 1)">
                          <mat-option *ngFor="let option of tablePages" [value]="option">Seite {{ option }}</mat-option>
                        </mat-select>
                      </div>
                    </div>
                  </section>
                </div>
                <!-- 
            <div class="col-md-3">
              <div class="form-group row_">
                <form class="example-form">
                  <label style="margin-bottom: -4px; display: block;">Suche:</label>
                  <mat-form-field class="example-full-width" style="width: 100%;">
                    <input type="text" matInput [formControl]="myControl" [(ngModel)]="filterBy" placeholder="Käufer"
                      style="width: auto;" (keyup)="filterOrdersBy('client', $event.target.value)" />
                  </mat-form-field>
                </form>
              </div>
            </div> -->

                <div class="col-md-12">
                  <section>
                    <div class="form-group actions">
                      <label for="">Aktionen</label><br />
                      <!-- <button class="btn btn-warning" *ngIf="filterBy" (click)="filterOrdersBy()">
                    <i class="fas fa-times"></i>&nbsp;Suche:&nbsp;{{ filterBy }}
                  </button> -->
                      <button class="btn btn-light" (click)="setDates('today', true)">heute</button>
                      <button class="btn btn-light" (click)="setDates('yesterday', true)">gestern</button>
                      <button class="btn btn-light" (click)="setDates('lastSeven', true)">letzten 7 Tage</button>
                      <button class="btn btn-light" (click)="setDates('thisWeek', true)">akt. Woche</button>
                      <button class="btn btn-light" (click)="setDates('lastWeek', true)">letzte Woche</button>
                      <button class="btn btn-light" (click)="setDates('thisMonth', true)">akt. Monat</button>
                      <button class="btn btn-light" (click)="setDates('lastMonth', true)">letzter Monat</button>
                      <!-- <small *ngIf="filterBy"><br />Filter: <a class="link" (click)="filterOrdersBy()">aufheben</a></small> -->
                    </div>
                  </section>
                </div>
              </div>
            </div>

            <div class="element-box datatable-res-transactions">
              <ngx-datatable
                class="material"
                [rows]="logs"
                [columnMode]="'force'"
                headerHeight="50"
                footerHeight="50"
                rowHeight="auto"
                [limit]="tableLimit"
                [sorts]="[{ prop: 'id', dir: 'desc' }]"
                [cssClasses]="{
                  pagerLeftArrow: 'fas fa-backward',
                  pagerRightArrow: 'fas fa-forward',
                  pagerPrevious: 'fas fa-step-backward',
                  pagerNext: 'fas fa-step-forward'
                }"
                [rowClass]="getRowClass"
              >
                <ngx-datatable-column [sortable]="true" name="Datum" prop="createdAt">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label"
                      >{{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }} -
                      {{ row.createdAt | amDateFormat: 'HH:mm' }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [sortable]="true" name="Reservierungsdatum" prop="action">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label" [ngClass]="{ 'red-class': row.action === 'guestCanceled' }">
                      <a
                        routerLink="/client/{{ row.reservation.betriebId }}/reservation/book/{{ row.reservation.id }}"
                        target="_blank"
                        title="Reservation ID: {{ row.reservation.id }}"
                        >{{ row.reservation.reservedFor | amDateFormat: 'DD.MM.YYYY' }} -
                        {{ row.reservation.reservedFor | amDateFormat: 'HH:mm' }} ({{ row.actionDetails }})
                      </a>
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [sortable]="false" name="Gastdaten" prop="guestData">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label" *ngIf="row.reservation.guestData">
                      {{ row.reservation.guestData.firstName }} {{ row.reservation.guestData.name }}
                      <br />
                      {{ row.reservation.guestData.email }}
                      <br />
                      {{ row.reservation.guestData.phone }}
                      <br />
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [sortable]="false" name="Details" prop="reservationData">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label" *ngIf="row.reservation">
                      Status: {{ row.status }}
                      <br />
                      Personen: {{ row.reservation.peopleCount }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [sortable]="false" name="Ticket Details" prop="user">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="row['reservation'] && row['reservation']['shift']">{{
                      row['reservation']['shift']['name']
                    }}</span
                    ><br />
                    <span
                      *ngIf="
                        row['reservation'] &&
                        row['reservation']['ticketOrder'] &&
                        row['reservation']['ticketOrder']['tickets'].length > 0
                      "
                      >{{ row['reservation']['ticketOrder']['tickets'][0]['ticket']['name'] }}</span
                    ><br />
                    <span
                      *ngIf="
                        row['reservation'] &&
                        row['reservation']['ticketOrder'] &&
                        row['reservation']['ticketOrder']['tickets'].length > 0
                      "
                    >
                      <span *ngFor="let ticket of row['reservation']['ticketOrder']['tickets']; let i = index">
                        <p>
                          {{ i + 1 }}. {{ ticket.subCategory.name }} ({{
                            (ticket.price ? ticket.price : ticket.subCategory.price) | currency: 'EUR'
                          }})
                        </p> </span
                      ><br />
                      <span *ngFor="let ticket of row['reservation']['ticketOrder']['tickets']; let i = index">
                        <div *ngIf="ticket.optionData && ticket.optionData.length">
                          <label>Add Ons:</label>
                          <ul>
                            <li *ngFor="let option of ticket.optionData">
                              {{ option.name }} - {{ option.count }} ({{ option.optionAmount | currency: 'EUR' }})
                            </li>
                          </ul>
                        </div>
                      </span>
                    </span>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>

              <div class="grid">
                <mat-paginator
                  [length]="logs?.length"
                  pageSize="{{ pageSize }}"
                  (page)="getPaginatorData($event)"
                  [pageSizeOptions]="[10, 20, 50]"
                  showFirstLastButtons="false"
                >
                </mat-paginator>
                <div class="row">
                  <ng-container *ngFor="let row of ordersMobile; let i = index">
                    <div
                      class="col-sm-6 col-md-6"
                      *ngIf="i < table.offset * tableLimit + tableLimit && i >= table.offset * tableLimit"
                    >
                      <div class="card" id="card-{{ row.id }}">
                        <div class="card-body">
                          <h6 class="card-title">
                            <!-- [ngClass]="{'red-class': row.action === 'guestCanceled'}" -->
                            <span class="price-label_">
                              <a
                                routerLink="/client/{{ row.reservation.betriebId }}/reservation/book/{{
                                  row.reservation.id
                                }}"
                                target="_blank"
                                title="Reservation ID: {{ row.reservation.id }}"
                              >
                                {{ row.reservation.reservedFor | amDateFormat: 'DD.MM.YYYY' }} -
                                {{ row.reservation.reservedFor | amDateFormat: 'HH:mm' }}
                                <br />
                                ({{ row.actionDetails }})
                              </a>
                            </span>
                            <br />
                            <br />
                            <p class="card-text" style="font-size: 14px;">
                              <span>
                                <ng-container>
                                  <span class="date-label">
                                    {{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }} -
                                    {{ row.createdAt | amDateFormat: 'HH:mm' }}</span
                                  >
                                </ng-container>
                              </span>
                              <span class="badge float-right" style="font-weight: normal;">{{ row.status }} </span>
                            </p>
                            <small> Personen: {{ row.reservation.peopleCount }} </small>
                          </h6>
                          <p class="card-text" style="font-size: 14px;">
                            <span class="date-label" *ngIf="row.reservation.guestData">
                              {{ row.reservation.guestData.firstName }} {{ row.reservation.guestData.name }}
                              <br />
                              {{ row.reservation.guestData.email }}
                              <br />
                              {{ row.reservation.guestData.phone }}
                              <br />
                            </span>

                            <span>
                              <b>Ticket Details: </b>
                              <span class="date-label" *ngIf="row['reservation'] && row['reservation']['shift']">{{
                                row['reservation']['shift']['name']
                              }}</span>
                              <br />
                              <span
                                class="date-label"
                                *ngIf="
                                  row['reservation'] &&
                                  row['reservation']['ticketOrder'] &&
                                  row['reservation']['ticketOrder']['tickets'].length > 0
                                "
                                >{{ row['reservation']['ticketOrder']['tickets'][0]['ticket']['name'] }}</span
                              >
                              <br />
                              <span
                                class="date-label"
                                *ngIf="
                                  row['reservation'] &&
                                  row['reservation']['ticketOrder'] &&
                                  row['reservation']['ticketOrder']['tickets'].length > 0
                                "
                              >
                                <span
                                  *ngFor="let ticket of row['reservation']['ticketOrder']['tickets']; let i = index"
                                >
                                  <p style="margin-bottom: 0px;">{{ i + 1 }}. {{ ticket.subCategory.name }}</p> </span
                                ><br />
                                <span
                                  *ngFor="let ticket of row['reservation']['ticketOrder']['tickets']; let i = index"
                                >
                                  <div *ngIf="ticket.optionData && ticket.optionData.length">
                                    <label>Add Ons:</label>
                                    <ul>
                                      <li *ngFor="let option of ticket.optionData">
                                        {{ option.name }} - {{ option.count }}
                                      </li>
                                    </ul>
                                  </div>
                                </span>
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="{{ recurrenceTab }}">
            <div class="element-box mat-wrapper">
              <div class="row">
                <div class="col-md-3">
                  <section>
                    <div class="form-group">
                      <label for="">Datum von</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateRecFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateRecFromPicker (closed)="dateRecFromInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateRecFromInput
                          placeholder=""
                          [(ngModel)]="recurrenceDate.from"
                          type="text"
                          name="date"
                          [matDatepicker]="dateRecFromPicker"
                          (focus)="dateRecFromPicker.open()"
                          (dateChange)="getRecurrences(false)"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-md-3">
                  <section>
                    <div class="form-group">
                      <label for="">Datum bis</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateRecToPicker (closed)="dateRecToInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateRecToInput
                          placeholder=""
                          [(ngModel)]="recurrenceDate.to"
                          type="text"
                          name="date"
                          [matDatepicker]="dateRecToPicker"
                          (focus)="dateRecToPicker.open()"
                          (dateChange)="getRecurrences(false)"
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <!-- 
            <div class="col-md-3">
              <div class="form-group row_">
                <form class="example-form">
                  <label style="margin-bottom: -4px; display: block;">Suche:</label>
                  <mat-form-field class="example-full-width" style="width: 100%;">
                    <input type="text" matInput [formControl]="myControl" [(ngModel)]="filterBy" placeholder="Käufer"
                      style="width: auto;" (keyup)="filterOrdersBy('client', $event.target.value)" />
                  </mat-form-field>
                </form>
              </div>
            </div> -->

                <div class="col-md-12">
                  <section>
                    <div class="form-group actions">
                      <label for="">Aktionen</label><br />
                      <!-- <button class="btn btn-warning" *ngIf="filterBy" (click)="filterOrdersBy()">
                    <i class="fas fa-times"></i>&nbsp;Suche:&nbsp;{{ filterBy }}
                  </button> -->
                      <button class="btn btn-light" (click)="setDates('today', false)">heute</button>
                      <button class="btn btn-light" (click)="setDates('yesterday', false)">gestern</button>
                      <button class="btn btn-light" (click)="setDates('lastSeven', false)">letzten 7 Tage</button>
                      <button class="btn btn-light" (click)="setDates('thisWeek', false)">akt. Woche</button>
                      <button class="btn btn-light" (click)="setDates('lastWeek', false)">letzte Woche</button>
                      <button class="btn btn-light" (click)="setDates('thisMonth', false)">akt. Monat</button>
                      <button class="btn btn-light" (click)="setDates('lastMonth', false)">letzter Monat</button>
                      <!-- <small *ngIf="filterBy"><br />Filter: <a class="link" (click)="filterOrdersBy()">aufheben</a></small> -->
                    </div>
                  </section>
                </div>
              </div>
            </div>

            <div class="element-box datatable-rec-transactions">
              <ngx-datatable
                class="material"
                [rows]="recurrencelogs"
                [columnMode]="'force'"
                headerHeight="50"
                footerHeight="50"
                rowHeight="auto"
                [limit]="tableLimit"
                [sorts]="[{ prop: 'id', dir: 'desc' }]"
                [cssClasses]="{
                  pagerLeftArrow: 'fas fa-backward',
                  pagerRightArrow: 'fas fa-forward',
                  pagerPrevious: 'fas fa-step-backward',
                  pagerNext: 'fas fa-step-forward'
                }"
                [rowClass]="getRowClass"
              >
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'RecurrenceTab.common.createdAt' | translate }}"
                  prop="createdAt"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label"
                      >{{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }} -
                      {{ row.createdAt | amDateFormat: 'HH:mm' }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'RecurrenceTab.ReservatiOnDate' | translate }}"
                  prop="action"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label" [ngClass]="{ 'red-class': row.action === 'guestCanceled' }">
                      <a
                        routerLink="/client/{{ row.reservationData.betriebId }}/reservation/book/{{
                          row.reservationData.id
                        }}"
                        target="_blank"
                        title="Reservation ID: {{ row.reservationData.id }}"
                        >{{ row.reservationData.reservedFor | amDateFormat: 'DD.MM.YYYY' }} -
                        {{ row.reservationData.reservedFor | amDateFormat: 'HH:mm' }}
                      </a>
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'RecurrenceTab.RepeatationType' | translate }}"
                  prop="repeatOption"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label" *ngIf="row.repeatOption == 'daily'">
                      {{ 'RecurrenceTab.common.daily' | translate }}
                    </span>
                    <span class="date-label" *ngIf="row.repeatOption == 'weekly'">
                      {{ 'RecurrenceTab.common.weekly' | translate }}
                    </span>
                    <span class="date-label" *ngIf="row.repeatOption == 'monthly'">
                      {{ 'RecurrenceTab.common.monthly' | translate }}
                    </span>
                    <!-- <span class="date-label"  *ngIf="row.repeatOption == 'yearly'">
                       {{ row.guestData.firstName }} {{ row.guestData.name }}
                     </span> -->
                    <span class="date-label" *ngIf="row.repeatOption == 'monthly_date'">
                      {{ 'RecurrenceTab.common.monthly_date' | translate }}
                    </span>
                    <span class="date-label" *ngIf="row.repeatOption == 'monthly_weekday'">
                      {{ 'RecurrenceTab.common.monthly_weekday' | translate }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [sortable]="false"
                  name="{{ 'RecurrenceTab.RepeatOn' | translate }}"
                  prop="repeatOn"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label" *ngIf="row.repeatOption == 'daily'">
                      {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}
                      {{ 'RecurrenceTab.common.days' | translate }}<br />
                    </span>
                    <span class="date-label" *ngIf="row.repeatOption == 'weekly'">
                      {{ 'RecurrenceTab.common.Every' | translate }} {{ row.ahead || '' }}. {{ row.weekDays || '' }}
                      <br />
                      {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}
                      {{ 'RecurrenceTab.common.Weeks' | translate }}
                    </span>
                    <span class="date-label" *ngIf="row.repeatOption == 'monthly'">
                      {{ 'RecurrenceTab.common.Every' | translate }} {{ row.repeatEvery || '' }}. eines Monats <br />
                      {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}
                      {{ 'RecurrenceTab.common.month' | translate }}<br />
                    </span>
                    <!-- <span class="date-label"  *ngIf="row.repeatOption == 'yearly'">
                      {{ row.guestData.firstName }} {{ row.guestData.name }}
                    </span> -->
                    <span class="date-label" *ngIf="row.repeatOption == 'monthly_date'">
                      {{ 'RecurrenceTab.common.Every' | translate }} {{ row.repeatEvery || '' }}. des Monats <br />
                      {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}.
                      {{ 'RecurrenceTab.common.month' | translate }}<br />
                    </span>
                    <span class="date-label" *ngIf="row.repeatOption == 'monthly_weekday'">
                      {{ 'RecurrenceTab.common.Every' | translate }} {{ row.repeatEvery }}. {{ row.weekDays }} im Monat
                      <br />
                      {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}
                      {{ 'RecurrenceTab.common.month' | translate }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [sortable]="false"
                  name="{{ 'RecurrenceTab.GuestData' | translate }}"
                  prop="guestData"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label" *ngIf="row.guestData">
                      {{ row.guestData.firstName }} {{ row.guestData.name }}
                      <br />
                      {{ row.guestData.email || '' }}
                      <br />
                      {{ row.guestData.phone || '' }}
                      <br />
                      <button class="btn btn-light view-btn" (click)="updateGuestData(row.guestData)">
                        <i class="fas fa-edit"></i>
                      </button>
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <!--  <ngx-datatable-column [sortable]="false" name="Details" prop="reservationData">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="date-label" *ngIf="row.reservation">
                      Status: {{ row.status }}
                      <br />
                      Personen: {{ row.reservation.peopleCount }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>-->
              </ngx-datatable>

              <div class="grid">
                <mat-paginator
                  [length]="recurrencelogs?.length"
                  pageSize="{{ pageRecSize }}"
                  (page)="getRecPaginatorData($event)"
                  [pageSizeOptions]="[10, 20, 50]"
                  showFirstLastButtons="false"
                >
                </mat-paginator>
                <div class="row">
                  <ng-container *ngFor="let row of recMobileLogs; let i = index">
                    <div
                      class="col-sm-6 col-md-6"
                      *ngIf="i < table.offset * tableLimit + tableLimit && i >= table.offset * tableLimit"
                    >
                      <div class="card" id="card-{{ row.reservationData.id }}">
                        <div class="card-body">
                          <h6 class="card-title">
                            <span class="price-label_">
                              <a
                                routerLink="/client/{{ row.reservationData.betriebId }}/reservation/book/{{
                                  row.reservationData.id
                                }}"
                                target="_blank"
                                title="Reservation ID: {{ row.reservationData.id }}"
                              >
                                {{ row.reservationData.reservedFor | amDateFormat: 'DD.MM.YYYY' }} -
                                {{ row.reservationData.reservedFor | amDateFormat: 'HH:mm' }}
                                <br />
                              </a>
                            </span>
                            <br />
                            <br />
                            <p class="card-text" style="font-size: 14px;">
                              <span>
                                <ng-container>
                                  <span class="date-label">
                                    {{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }} -
                                    {{ row.createdAt | amDateFormat: 'HH:mm' }}</span
                                  >
                                </ng-container>
                              </span>
                            </p>
                          </h6>
                          <p class="card-text" style="font-size: 14px;">
                            <span class="date-label" *ngIf="row.repeatOption == 'daily'">
                              {{ 'RecurrenceTab.common.daily' | translate }}
                            </span>
                            <span class="date-label" *ngIf="row.repeatOption == 'weekly'">
                              {{ 'RecurrenceTab.common.weekly' | translate }}
                            </span>
                            <span class="date-label" *ngIf="row.repeatOption == 'monthly'">
                              {{ 'RecurrenceTab.common.monthly' | translate }}
                            </span>
                            <!-- <span class="date-label"  *ngIf="row.repeatOption == 'yearly'">
                               {{ row.guestData.firstName }} {{ row.guestData.name }}
                             </span> -->
                            <span class="date-label" *ngIf="row.repeatOption == 'monthly_date'">
                              {{ 'RecurrenceTab.common.monthly_date' | translate }}
                            </span>
                            <span class="date-label" *ngIf="row.repeatOption == 'monthly_weekday'">
                              {{ 'RecurrenceTab.common.monthly_weekday' | translate }}
                            </span>
                          </p>
                          <p class="card-text" style="font-size: 14px;">
                            <span class="date-label" *ngIf="row.repeatOption == 'daily'">
                              {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}
                              {{ 'RecurrenceTab.common.days' | translate }}<br />
                            </span>
                            <span class="date-label" *ngIf="row.repeatOption == 'weekly'">
                              {{ 'RecurrenceTab.common.Every' | translate }} {{ row.ahead || '' }}.
                              {{ row.weekDays || '' }} <br />
                              {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}
                              {{ 'RecurrenceTab.common.Weeks' | translate }}
                            </span>
                            <span class="date-label" *ngIf="row.repeatOption == 'monthly'">
                              {{ 'RecurrenceTab.common.Every' | translate }} {{ row.repeatEvery || '' }}. eines Monats
                              <br />
                              {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}
                              {{ 'RecurrenceTab.common.month' | translate }}<br />
                            </span>
                            <!-- <span class="date-label"  *ngIf="row.repeatOption == 'yearly'">
                              {{ row.guestData.firstName }} {{ row.guestData.name }}
                            </span> -->
                            <span class="date-label" *ngIf="row.repeatOption == 'monthly_date'">
                              {{ 'RecurrenceTab.common.Every' | translate }} {{ row.repeatEvery || '' }}. des Monats
                              <br />
                              {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}.
                              {{ 'RecurrenceTab.common.month' | translate }}<br />
                            </span>
                            <span class="date-label" *ngIf="row.repeatOption == 'monthly_weekday'">
                              {{ 'RecurrenceTab.common.Every' | translate }} {{ row.repeatEvery }}.
                              {{ row.weekDays }} im Monat <br />
                              {{ 'RecurrenceTab.common.duration' | translate }}: {{ row.ahead || '' }}
                              {{ 'RecurrenceTab.common.month' | translate }}
                            </span>
                          </p>
                          <p class="card-text" style="font-size: 14px;">
                            <span class="date-label" *ngIf="row.guestData">
                              {{ row.guestData.firstName }} {{ row.guestData.name }}
                              <br />
                              {{ row.guestData.email || '' }}
                              <br />
                              {{ row.guestData.phone || '' }}
                              <br />
                              <button class="btn btn-light view-btn" (click)="updateGuestData(row.guestData)">
                                <i class="fas fa-edit"></i>
                              </button>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Reservation-list">
            <div *ngIf="showReservationList">
              <app-reservation-list-by-date></app-reservation-list-by-date>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
