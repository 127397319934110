var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { Room } from '../reservation-settings.model';
import { LabelType } from 'ng5-slider';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from '@app/reservation/reservation.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
// import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { MenucardsService } from '@app/menucards/menucards.service';
import moment from 'moment';
import { EventsService } from '@app/events/events.service';
import { takeUntil } from 'rxjs/operators';
export class AddShiftComponent {
    constructor(activeModal, reservationService, formBuilder, snackBar, menucardsService, eventsService) {
        this.activeModal = activeModal;
        this.reservationService = reservationService;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.menucardsService = menucardsService;
        this.eventsService = eventsService;
        this.passEntry = new EventEmitter();
        this.globalQuestions = [];
        this.paymentTemplates = [];
        this.shiftTypes = [
            {
                label: 'Default',
                value: 0
            },
            {
                label: 'Ticket',
                value: 1
            },
            {
                label: 'Table',
                value: 2
            }
        ];
        this.stayTimes = [
            {
                guestCountFrom: 1,
                guestCountTo: 2,
                minStayTime: 30,
                maxStayTime: 180,
                defaultStayTime: 60
            },
            {
                guestCountFrom: 3,
                guestCountTo: 5,
                minStayTime: 60,
                maxStayTime: 240,
                defaultStayTime: 120
            },
            {
                guestCountFrom: 6,
                guestCountTo: null,
                minStayTime: 90,
                maxStayTime: 240,
                defaultStayTime: 150
            }
        ];
        this.bufferTimes = [
            {
                label: 'Keine Pufferzeit',
                value: 0
            },
            {
                label: '30 Minuten',
                value: 0.5
            },
            {
                label: '60 Minuten',
                value: 1
            },
            {
                label: '90 Minuten',
                value: 1.5
            }
        ];
        this.cleaningTimes = [
            { label: 'Keine Pufferzeit', value: 0 },
            { label: '5 Minuten', value: 5 },
            { label: '10 Minuten', value: 10 },
            { label: '15 Minuten', value: 15 },
            { label: '20 Minuten', value: 20 },
            { label: '30 Minuten', value: 30 },
            { label: '60 Minuten', value: 60 }
        ];
        this.reservationIntervals = [
            {
                label: '10 Minuten',
                value: 10
            },
            {
                label: '15 Minuten',
                value: 15
            },
            {
                label: '30 Minuten',
                value: 30
            },
            {
                label: '60 Minuten',
                value: 60
            }
        ];
        this.addOns = [];
        this.menuItems = [];
        this.activeEventList = [];
        this.activeTicketList = [];
        this.staticStayTimeOptions = {
            floor: 30,
            ceil: 600,
            step: 30,
            showTicks: true,
            translate: (value, label) => {
                value = value / 60; // Convert to hours
                if (label !== LabelType.Floor && label !== LabelType.Ceil) {
                    switch (value) {
                        case 1:
                            return value + ' Stunde';
                        default:
                            return value + ' Stunden';
                    }
                }
                else {
                    return value + '';
                }
            }
        };
        this.sliderOptions = {
            floor: 30,
            ceil: 240,
            step: 30,
            showTicks: true,
            translate: (value, label) => {
                value = value / 60; // Convert to hours
                switch (label) {
                    case LabelType.Low:
                        if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            return value + ' Stunden';
                        }
                    case LabelType.High:
                        if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' Stunde';
                        }
                        else {
                            return value + ' Stunden';
                        }
                    default:
                        return value + '';
                }
            }
        };
        this.ngUnsubscribe = new Subject();
        this.shiftFormGroup = this.formBuilder.group({
            color: ['#F44336'],
            name: ['', Validators.required],
            description: [''],
            info: [''],
            emailInfo: [''],
            rooms: [[], Validators.required],
            minGuests: [1],
            maxGuests: [10],
            buffer: [1, Validators.required],
            interval: [15, Validators.required],
            stayTimeType: ['static'],
            maxGuestsMsg: [''],
            noSlotAvailableMsg: [''],
            addOns: [[]],
            menuItem: [''],
            type: [0, Validators.required],
            amountLabelSingle: [''],
            amountLabelPlural: [''],
            stayTimes: this.formBuilder.array([
                this.formBuilder.group({
                    guestCountFrom: 1,
                    guestCountTo: 2,
                    minStayTime: 30,
                    maxStayTime: 180,
                    defaultStayTime: 60
                }),
                this.formBuilder.group({
                    guestCountFrom: 3,
                    guestCountTo: 5,
                    minStayTime: 60,
                    maxStayTime: 240,
                    defaultStayTime: 120
                }),
                this.formBuilder.group({
                    guestCountFrom: 6,
                    guestCountTo: null,
                    minStayTime: 90,
                    maxStayTime: 240,
                    defaultStayTime: 150
                })
            ]),
            maxGuestsPerUnit: this.formBuilder.array([]),
            questions: this.formBuilder.array([]),
            noReservationAfterShiftStart: [false],
            groupReservation: [false],
            paymentTemplate: [],
            eventId: [],
            ticketId: [],
            isAutomatic: false,
            autoPlace: false,
            cleaningTime: [0]
        });
        for (let index = 2; index <= 24; index++) {
            this.bufferTimes.push({
                label: index + (index === 1 ? ' Stunde' : ' Stunden'),
                value: index
            });
        }
    }
    get formData() {
        return this.shiftFormGroup.get('maxGuestsPerUnit');
    }
    get questionsFormData() {
        return this.shiftFormGroup.get('questions');
    }
    getQuestions() {
        this.reservationService
            .getQuestions()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((questions) => {
            this.globalQuestions = questions;
        });
    }
    getPaymentTemplates() {
        this.reservationService
            .getPaymentTemplates()
            .takeUntil(this.ngUnsubscribe)
            .subscribe((paymentTemplates) => {
            this.paymentTemplates = paymentTemplates;
        });
    }
    createUnit() {
        return this.formBuilder.group({
            day: [0, Validators.required],
            time: ['', Validators.required],
            guestCount: ['', Validators.required]
        });
    }
    createQuestion() {
        return this.formBuilder.group({
            questionId: [Validators.required],
            required: ['0', Validators.required],
            questionType: [0, Validators.required]
        });
    }
    formatLabel(value) {
        if (!value) {
            return 0;
        }
        return value + ' Stunden';
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('settings', this.generalSettings);
            const { role } = JSON.parse(localStorage.getItem('credentials'));
            this.isAccess = role === 'admin' ? true : false;
            this.getMenucards();
            this.getQuestions();
            this.getPaymentTemplates();
            yield this.getAllActiveEvents();
            if (this.editShiftData) {
                this.shiftFormGroup.addControl('id', new FormControl(this.editShiftData.id, Validators.required));
                this.shiftFormGroup.get('color').setValue(this.editShiftData.color);
                this.shiftFormGroup.get('name').setValue(this.editShiftData.name);
                this.shiftFormGroup.get('description').setValue(this.editShiftData.description);
                this.shiftFormGroup.get('info').setValue(this.editShiftData.info);
                this.shiftFormGroup.get('emailInfo').setValue(this.editShiftData.emailInfo);
                this.shiftFormGroup.get('rooms').setValue(this.editShiftData.rooms);
                this.shiftFormGroup.get('addOns').setValue(this.editShiftData.addOns);
                this.shiftFormGroup.get('menuItem').setValue(this.editShiftData.menuItem);
                this.shiftFormGroup.get('minGuests').setValue(this.editShiftData.minGuests);
                this.shiftFormGroup.get('maxGuests').setValue(this.editShiftData.maxGuests);
                this.shiftFormGroup.get('maxGuestsMsg').setValue(this.editShiftData.maxGuestsMsg);
                this.shiftFormGroup.get('noSlotAvailableMsg').setValue(this.editShiftData.noSlotAvailableMsg);
                this.shiftFormGroup.get('buffer').setValue(this.editShiftData.buffer);
                this.shiftFormGroup.get('interval').setValue(this.editShiftData.interval);
                this.shiftFormGroup.get('stayTimeType').setValue(this.editShiftData.stayTimeType);
                this.shiftFormGroup.get('paymentTemplate').setValue(this.editShiftData.paymentTemplate);
                this.shiftFormGroup.get('groupReservation').setValue(this.editShiftData.groupReservation);
                this.shiftFormGroup.get('eventId').setValue(this.editShiftData.eventId);
                this.getAllRelatedTicket();
                this.shiftFormGroup.get('ticketId').setValue(this.editShiftData.ticketId);
                this.shiftFormGroup.get('type').setValue(this.editShiftData.type);
                this.shiftFormGroup.get('isAutomatic').setValue(this.editShiftData.isAutomatic);
                this.shiftFormGroup.get('autoPlace').setValue(this.editShiftData.autoPlace);
                this.shiftFormGroup.get('cleaningTime').setValue(this.editShiftData.cleaningTime || 0);
                if (this.editShiftData.tableLabel &&
                    (this.editShiftData.tableLabel.length > 0 || Object.keys(this.editShiftData.tableLabel).length > 0)) {
                    this.shiftFormGroup.get('amountLabelSingle').setValue(this.editShiftData.tableLabel.amountLabelSingle);
                    this.shiftFormGroup.get('amountLabelPlural').setValue(this.editShiftData.tableLabel.amountLabelPlural);
                }
                const stayTimes = [];
                this.stayTimes = [];
                this.editShiftData.stayTimes.forEach((st) => {
                    stayTimes.push(this.formBuilder.group(st));
                    this.stayTimes.push(st);
                });
                this.shiftFormGroup.setControl('stayTimes', this.formBuilder.array(stayTimes));
                // Max guests per units
                const maxGuestsUnits = [];
                if (this.editShiftData.maxGuestsPerUnit) {
                    this.editShiftData.maxGuestsPerUnit.forEach((st) => {
                        maxGuestsUnits.push(this.formBuilder.group(st));
                    });
                    this.shiftFormGroup.setControl('maxGuestsPerUnit', this.formBuilder.array(maxGuestsUnits));
                }
                // Questions
                const questions = [];
                if (this.editShiftData.questions) {
                    this.editShiftData.questions = this.editShiftData.questions.sort((a, b) => (a.ReservationShiftQuestion && a.ReservationShiftQuestion.id ? a.ReservationShiftQuestion.id : a.id) -
                        (b.ReservationShiftQuestion && b.ReservationShiftQuestion.id ? b.ReservationShiftQuestion.id : b.id));
                    this.editShiftData.questions.forEach((st) => {
                        let questionValue = st.ReservationShiftQuestion ? st.ReservationShiftQuestion : st;
                        st.required = questionValue.required.toString();
                        questions.push(this.formBuilder.group(st));
                    });
                    this.shiftFormGroup.setControl('questions', this.formBuilder.array(questions));
                }
                this.shiftFormGroup.get('noReservationAfterShiftStart').setValue(this.editShiftData.noReservationAfterShiftStart);
            }
            // Get available rooms
            this.reservationService
                .getRooms()
                .takeUntil(this.ngUnsubscribe)
                .subscribe((rooms) => {
                this.rooms = rooms;
            });
            // Get available addOns
            this.reservationService
                .getReservationAddOnTemplates()
                .takeUntil(this.ngUnsubscribe)
                .subscribe((addOns) => {
                this.addOns = addOns;
            });
        });
    }
    getMenucards() {
        this.menucardsService.getClientMenus().subscribe((menus) => {
            this.menuItems = menus.filter((menu) => {
                if (!(menu.validUntil && moment(new Date()).isAfter(menu.validUntil)) && menu.isActive) {
                    return menu;
                }
            });
        });
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    add() {
        const stayTimes = [];
        this.stayTimes.forEach((st) => {
            stayTimes.push(this.formBuilder.group(st));
        });
        this.shiftFormGroup.setControl('stayTimes', this.formBuilder.array(stayTimes));
        if (!this.shiftFormGroup.valid) {
            this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        const addData = this.shiftFormGroup.value;
        if (addData.rooms.length) {
            addData.rooms = addData.rooms.map((room) => room.id);
        }
        if (addData.addOns && addData.addOns.length) {
            addData.addOns = addData.addOns.map((addOn) => addOn.id);
        }
        this.addObservable = this.reservationService
            .addShift(addData)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.passEntry.emit(response);
        });
    }
    save() {
        const stayTimes = [];
        this.stayTimes.forEach((st) => {
            stayTimes.push(this.formBuilder.group(st));
        });
        this.shiftFormGroup.setControl('stayTimes', this.formBuilder.array(stayTimes));
        if (!this.shiftFormGroup.valid) {
            this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        const editData = this.shiftFormGroup.value;
        if (editData.rooms.length) {
            editData.rooms = editData.rooms.map((room) => room.id);
        }
        if (editData.addOns && editData.addOns.length) {
            editData.addOns = editData.addOns.map((addOn) => addOn.id);
        }
        this.editObservable = this.reservationService
            .editShift(editData)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.passEntry.emit(response);
        });
    }
    changeColor(event) {
        this.shiftFormGroup.get('color').setValue(event);
    }
    getDefaultValues(min, max) {
        const step = 30;
        return Array(Math.ceil((max - min) / step) + 1)
            .fill(min)
            .map((x, y) => x + y * step);
    }
    minGuestsChanged(event) {
        this.shiftFormGroup.get('minGuests').setValue(event);
    }
    maxGuestsChanged(event) {
        this.shiftFormGroup.get('maxGuests').setValue(event);
    }
    addNewUnit() {
        this.maxGuestsPerUnit = this.shiftFormGroup.get('maxGuestsPerUnit');
        this.maxGuestsPerUnit.push(this.createUnit());
    }
    removeUnit(index) {
        this.maxGuestsPerUnit = this.shiftFormGroup.get('maxGuestsPerUnit');
        this.maxGuestsPerUnit.removeAt(index);
    }
    addQuestion() {
        this.questions = this.shiftFormGroup.get('questions');
        this.questions.push(this.createQuestion());
    }
    removeQuestion(index) {
        this.questions = this.shiftFormGroup.get('questions');
        this.questions.removeAt(index);
    }
    changeQuestions(question, i) {
        if (this.questions && this.questions.length > 0 && this.questions.value[i]) {
            this.questions.value[i].questionType = question.questionType;
            if (this.questions.value[i].questionType == 3) {
                const myForm = this.shiftFormGroup.get('questions').at(i);
                myForm.get('required').setValue('1');
                myForm.get('questionType').setValue(3);
            }
        }
    }
    getAllActiveEvents() {
        return __awaiter(this, void 0, void 0, function* () {
            this.activeEventList = [];
            try {
                const events = yield this.eventsService
                    .getEvents('active')
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .toPromise();
                this.activeEventList = events;
            }
            catch (error) {
                console.error('Error fetching events', error);
            }
        });
    }
    getAllRelatedTicket() {
        this.shiftFormGroup.get('ticketId').setValue(null);
        this.activeTicketList = [];
        const eventId = this.shiftFormGroup.value.eventId;
        if (eventId) {
            const eventDetail = this.activeEventList.find((item) => item.id == eventId);
            if (eventDetail) {
                this.activeTicketList = eventDetail.tickets;
            }
        }
    }
}
