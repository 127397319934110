var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy, SimpleChanges, OnChanges, ChangeDetectorRef, ElementRef, NgZone, QueryList, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService, CredentialsService, Constants } from '@app/core';
import { ReservationService } from '@app/reservation/reservation.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LabelType } from 'ng5-slider';
import { MatStepper, DateAdapter } from '@angular/material';
import moment from 'moment';
import { ClientService } from '@app/core/client.service';
import { GoogleCloudTranslateService } from '@app/core/google-cloud-translate.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Meta, Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
// import { StripeService, Elements, Element as StripeElement, ElementsOptions } from '@nomadreservations/ngx-stripe';
import 'rxjs/add/operator/takeUntil';
import { PaymentService } from '@app/shared/payment/payment.service';
// import { RequestElementOptions, PaymentRequestButtonStyle } from '@nomadreservations/ngx-stripe/lib/interfaces/element';
import * as WebFont from 'webfontloader';
import { pluck } from 'rxjs/operators';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { addDays } from 'date-fns';
import { retryWhen, delay, take } from 'rxjs/operators';
import { HandleStateForPipeService } from './handle-state-for-pipe.service';
import { SpecialCharactersPipe } from '@app/shared/special-characters.pipe';
import { MatSnackBar } from '@angular/material';
import { HelpersService } from '@app/core/helpers.service';
export class ReservationComponent {
    constructor(clientService, route, reservationService, paymentService, formBuilder, i18n, router, credentialsService, translateService, deviceService, metaTagService, titleService, cd, location, zone, dateConfig, googleCloudTranslateService, dateAdapter, stateService, snackBar) {
        this.clientService = clientService;
        this.route = route;
        this.reservationService = reservationService;
        this.paymentService = paymentService;
        this.formBuilder = formBuilder;
        this.i18n = i18n;
        this.router = router;
        this.credentialsService = credentialsService;
        this.translateService = translateService;
        this.deviceService = deviceService;
        this.metaTagService = metaTagService;
        this.titleService = titleService;
        this.cd = cd;
        this.location = location;
        this.zone = zone;
        this.dateConfig = dateConfig;
        this.googleCloudTranslateService = googleCloudTranslateService;
        this.dateAdapter = dateAdapter;
        this.stateService = stateService;
        this.snackBar = snackBar;
        this.isLoading = false;
        this.isAdmin = false;
        this.guestCountSelect = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
        this.questions = [];
        this.loadingFreeTables = false;
        this.alternativeTimes = [];
        this.isEditable = true;
        this.iFrameMode = false;
        this.alerts = [];
        this.isFormSubmitting = false;
        this.todayDate = new Date();
        this.countryCodes = [];
        this.resMenuOrderItems = [];
        this.tags = [];
        this.version = 0;
        this.allTickets = [];
        this.showMultiSelectOption = [0, 4];
        this.stayTimeOptions = {
            floor: 30,
            ceil: 600,
            step: 30,
            showTicks: true,
            showSelectionBar: true,
            translate: (value, label) => {
                value = value / 60; // Convert to hours
                switch (label) {
                    case LabelType.Floor:
                        if (value === 0.5) {
                            return '30 ' + this.translateService.instant('widget.reservation.minutes');
                        }
                        else if (value === 1) {
                            return value + ' ' + this.translateService.instant('widget.reservation.hour');
                        }
                        else {
                            return value + ' ' + this.translateService.instant('widget.reservation.hours');
                        }
                    case LabelType.Ceil:
                        if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' ' + this.translateService.instant('widget.reservation.hour');
                        }
                        else {
                            return value + ' ' + this.translateService.instant('widget.reservation.hours');
                        }
                    case LabelType.Low:
                        if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' ' + this.translateService.instant('widget.reservation.hour');
                        }
                        else {
                            return value + ' ' + this.translateService.instant('widget.reservation.hours');
                        }
                    case LabelType.High:
                        if (value === 0.5) {
                            return '30 Minuten';
                        }
                        else if (value === 1) {
                            return value + ' ' + this.translateService.instant('widget.reservation.hour');
                        }
                        else {
                            return value + ' ' + this.translateService.instant('widget.reservation.hours');
                        }
                    default:
                        return value + '';
                }
            }
        };
        this.alternativeTimesFollowUpShift = [];
        this.loadingFreeTablesFollowUpShift = false;
        this.disableNextButton = false;
        /*Res Ticketing */
        this.ticketData = [];
        this.selectedTicketData = [];
        this.selectedTicketPrice = 0;
        this.selectedTicketAddOn = [];
        this.selectedTicketAddOnPrice = 0;
        this.selectedTicketSubCategoryPrice = 0;
        this.moveForward = true;
        this.guestCountArray = [];
        this.reservationDataValues = [];
        this.ticketHolder = [];
        this.generalTicketSettings = [];
        this.serviceCharge = 0;
        this.finalPriceAfterVoucher = 0;
        this.showBuyFreeButton = false;
        this.showClientConfirmPage = false;
        // Payment
        this.showPayment = false;
        this.cardHandler = this.onChange.bind(this);
        this.elementsOptions = {
            locale: 'de'
        };
        this.paymentSuccess = false;
        this.editPage = false;
        this.nativePayments = {
            applePay: false,
            googlePay: false,
            microsoftPay: false
        };
        this.processingPaypal = false;
        this.editErrorMsg = '';
        this.ngUnsubscribe = new Subject();
        this.questionAnswer = [];
        this.isMandatory = false;
        this.answerCount = 0;
        this.CompulsoryQuestion = 0;
        this.allReservationAddOnTemplates = [];
        this.reservationAddOnTemplates = [];
        this.selectedAddOns = [];
        this.showReservationData = true;
        this.countries = [];
        this.allAvailableDates = [];
        this.mobileWindow = false;
        this.showShiftFirstTemplate = false;
        this.translatedData = [];
        this.translatedTexts = [];
        this.languageArray = [];
        this.isDataChanged = false;
        this.callGoogleTranslateAPI = false;
        this.captchaToken = '';
        this.reservationToken = '';
        this.hasTimedOut = false;
        this.dateFilter = (date) => {
            if (this.preSelectedShift || this.showShiftFirstTemplate) {
                const dateString = this.formatDate(date);
                return this.allAvailableDates.includes(dateString);
            }
            else {
                return true;
            }
        };
        this.mobileWindow = window.innerWidth <= 768 ? true : false;
        // Setup translations
        this.i18n.init(environment.defaultLanguage, environment.supportedLanguages);
        this.languageArray = [
            {
                lang: 'NL',
                value: 'nl',
                flag: `${environment.baseUrl}assets/img/flags-icons/nl.png`
            },
            {
                lang: 'GB',
                value: 'en',
                flag: `${environment.baseUrl}assets/img/flags-icons/uk.png`
            },
            {
                lang: 'DE',
                value: 'de',
                flag: `${environment.baseUrl}assets/img/flags-icons/de.png`
            },
            {
                lang: 'ES',
                value: 'es',
                flag: `${environment.baseUrl}assets/img/flags-icons/es.png`
            },
            {
                lang: 'FR',
                value: 'fr',
                flag: `${environment.baseUrl}assets/img/flags-icons/fr.png`
            },
            {
                lang: 'IT',
                value: 'it',
                flag: `${environment.baseUrl}assets/img/flags-icons/it.png`
            }
        ];
    }
    ngOnInit() {
        /* Followup Shift stay Time*/
        this.stayTimeOptionsFollowUpShift = this.stayTimeOptions;
        /* Fetch shift and date from URL if defined*/
        if (this.widgetShiftId) {
            this.preSelectedShift = this.widgetShiftId;
        }
        else {
            this.preSelectedShift = this.route.snapshot.paramMap.get('shift');
        }
        this.preSelectedDate = this.route.snapshot.paramMap.get('date');
        for (let i = 21; i <= 50; i++) {
            this.guestCountSelect.push(i);
        }
        console.log('entering 1.9.18.1');
        // Test
        this.stripeData = this.formBuilder.group({
            name: ['', [Validators.required]]
        });
        this.questionsForm = this.formBuilder.group({
            description: this.formBuilder.array([])
        });
        this.reservationFormGroup = this.formBuilder.group({
            guestCount: ['', Validators.required],
            date: ['', Validators.required],
            time: ['', Validators.required],
            stayTime: ['', Validators.required],
            room: ['']
        });
        this.guestDetailsFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            surname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.minLength(8)]],
            addMsg: [''],
            informSms: [''],
            newsletter: [''],
            countryCode: ['', [Validators.required]],
            generateInvoice: [false],
            company: [''],
            address: [''],
            zipcode: [''],
            city: ['']
        });
        this.savedData = localStorage.getItem('orderSavedData') ? JSON.parse(localStorage.getItem('orderSavedData')) : null;
        if (this.savedData) {
            this.isUserSavingHisData = true;
            this.guestDetailsFormGroup.get('name').patchValue(this.savedData.name);
            this.guestDetailsFormGroup.get('surname').patchValue(this.savedData.surname);
            this.guestDetailsFormGroup.get('email').patchValue(this.savedData.email);
            this.guestDetailsFormGroup.get('phone').patchValue(this.validatePhoneNo(this.savedData.phone));
            this.guestDetailsFormGroup.get('addMsg').patchValue(this.savedData.addMsg);
            this.guestDetailsFormGroup.get('countryCode').patchValue(this.savedData.countryCode);
            // this.guestDetailsFormGroup.get('generateInvoice').patchValue(this.savedData.generateInvoice);
            this.guestDetailsFormGroup.get('company').patchValue(this.savedData.company);
            this.guestDetailsFormGroup.get('address').patchValue(this.savedData.address);
            this.guestDetailsFormGroup.get('zipcode').patchValue(this.savedData.zipcode);
            this.guestDetailsFormGroup.get('city').patchValue(this.savedData.city);
        }
        this.getReferrer();
        this.getClient(true);
        this.iFrameMode = this.widgetClientId ? true : false;
        this.route.queryParams.takeUntil(this.ngUnsubscribe).subscribe(params => {
            if (!params) {
                return;
            }
            this.isAdmin = params['debug'] ? true : false;
            this.iFrameMode = this.iFrameMode || params['iFrame'] ? true : false;
        });
        // Set style
        document.documentElement.style.setProperty('--status-color', '#028e00');
        if (this.overrideSettings) {
            document.documentElement.style.setProperty('--main-color', this.overrideSettings.mainColor);
            document.documentElement.style.setProperty('--primary', this.overrideSettings.primaryColor);
            document.documentElement.style.setProperty('--background', this.overrideSettings.backgroundColor);
            document.documentElement.style.setProperty('--primary-font', this.overrideSettings.primaryFont);
            document.documentElement.style.setProperty('--secondary-font', this.overrideSettings.secondaryFont);
            document.documentElement.style.setProperty('--alert-color', this.overrideSettings.alertColor);
            document.documentElement.style.setProperty('--form-background', this.overrideSettings.formBackgroundColor
                ? this.overrideSettings.formBackgroundColor
                : this.overrideSettings.backgroundColor);
            // WebFont.load({
            //   google: {
            //     families: [this.overrideSettings.primaryFont, this.overrideSettings.secondaryFont]
            //   }
            // });
            WebFont.load({
                custom: {
                    families: [this.overrideSettings.primaryFont],
                    urls: [`https://fonts.gastroguide.de/${this.overrideSettings.primaryFont}`]
                }
            });
            WebFont.load({
                custom: {
                    families: [this.overrideSettings.secondaryFont],
                    urls: [`https://fonts.gastroguide.de/${this.overrideSettings.secondaryFont}`]
                }
            });
        }
        let clientId = this.widgetClientId;
        if (this.widgetClientId) {
            clientId = this.widgetClientId;
        }
        else {
            clientId = this.route.snapshot.paramMap.get('clientId');
        }
        this.reservationService
            .getWidgetStylePublic(clientId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((settings) => {
            if (settings) {
                this.overrideSettings = {};
                this.overrideSettings.mainColor = settings.mainColor;
                this.overrideSettings.primaryColor = settings.primaryColor;
                this.overrideSettings.backgroundColor = settings.backgroundColor;
                this.overrideSettings.primaryFont = settings.primaryFont;
                this.overrideSettings.secondaryFont = settings.secondaryFont;
                this.overrideSettings.alertColor = settings.alertColor;
                this.overrideSettings.formBackgroundColor = settings.formBackgroundColor;
                document.documentElement.style.setProperty('--main-color', this.overrideSettings.mainColor);
                document.documentElement.style.setProperty('--primary', this.overrideSettings.primaryColor);
                document.documentElement.style.setProperty('--background', this.overrideSettings.backgroundColor);
                document.documentElement.style.setProperty('--primary-font', this.overrideSettings.primaryFont);
                document.documentElement.style.setProperty('--secondary-font', this.overrideSettings.secondaryFont);
                document.documentElement.style.setProperty('--alert-color', this.overrideSettings.alertColor);
                document.documentElement.style.setProperty('--form-background', this.overrideSettings.formBackgroundColor
                    ? this.overrideSettings.formBackgroundColor
                    : this.overrideSettings.backgroundColor);
                // WebFont.load({
                //   google: {
                //     families: [this.overrideSettings.primaryFont, this.overrideSettings.secondaryFont]
                //   }
                // });
                WebFont.load({
                    custom: {
                        families: [this.overrideSettings.primaryFont],
                        urls: [`https://fonts.gastroguide.de/${this.overrideSettings.primaryFont}`]
                    }
                });
                WebFont.load({
                    custom: {
                        families: [this.overrideSettings.secondaryFont],
                        urls: [`https://fonts.gastroguide.de/${this.overrideSettings.secondaryFont}`]
                    }
                });
            }
        });
    }
    ngAfterViewInit() {
        this.dropdowns.forEach(dropdown => {
            dropdown.nativeElement.addEventListener('click', (event) => {
                event.preventDefault();
            });
        });
    }
    storeTranslationLog() {
        const logs = {
            clientId: this.client.id,
            language: this.userLang
        };
        this.reservationService.saveGoogleTranslationLogs(logs, this.userLang).subscribe({
            next(value) {
                console.log(value);
            },
            error(err) {
                console.log(err);
            }
        });
    }
    checkPreSelectedData(selectedShift) {
        this.allAvailableDates = [];
        this.selectedDate = '';
        this.openingHoursToday = '';
        this.alerts = [];
        this.resetForm();
        if (selectedShift) {
            this.reservationService
                .getShiftAvailableDate(this.client.id, selectedShift, this.userLang)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((res) => {
                this.preSelectedShiftObject = res && res.shift ? res.shift : null;
                if (!this.selectedShiftObject) {
                    this.selectedShiftObject = this.preSelectedShiftObject;
                }
                if (res && res.finalTimeSeries) {
                    this.selectedShift = selectedShift;
                    for (let item of res.finalTimeSeries) {
                        if (!this.allAvailableDates.includes(item)) {
                            this.allAvailableDates.push(item);
                        }
                    }
                    this.allAvailableDates = [...this.allAvailableDates];
                    this.datesToHighlight = true;
                    if (this.allAvailableDates && this.allAvailableDates.length > 0) {
                        if (this.preSelectedDate) {
                            this.selectedDate = moment(this.preSelectedDate).format('YYYY-MM-DD');
                            if (this.allAvailableDates.includes(this.preSelectedDate)) {
                                this.getShifts();
                            }
                            else {
                                this.translateService
                                    .get('widget.reservation.noSpaceOptions')
                                    .takeUntil(this.ngUnsubscribe)
                                    .subscribe((text) => {
                                    this.errorMsg = text;
                                });
                                // this.errorMsg = `${this.translateService.instant(
                                //   'widget.reservation.shiftNotAvailableDate'
                                // )} ${moment(this.preSelectedDate).format('DD MMMM, dddd')}`;
                            }
                        }
                        // else {
                        //   this.selectedDate = moment(this.allAvailableDates[0]).format('YYYY-MM-DD');
                        // }
                    }
                }
            }, error => {
                this.openingHoursToday = null;
                this.errorMsg = error.error.msg;
                // this.shifts = [];
            });
        }
    }
    validatePhoneNo(phoneNo, countryCodes = []) {
        phoneNo = phoneNo.replace(/\s+/g, '');
        if (countryCodes && countryCodes.length > 0) {
            let code = phoneNo.indexOf('+') < 0 ? phoneNo.substring(0, 2) : phoneNo.substring(0, 3);
            if (countryCodes.includes(code)) {
                phoneNo = phoneNo.replace(code, '');
            }
            else if (countryCodes.includes(`+${code}`)) {
                phoneNo = phoneNo.slice(2);
            }
        }
        phoneNo = phoneNo.replace(/^0+/, '');
        if (phoneNo.indexOf('+') > -1 && countryCodes && countryCodes.length > 0) {
            phoneNo = this.validatePhoneNo(phoneNo, countryCodes);
        }
        return phoneNo;
    }
    checkEditOrPaymentPage() {
        this.editPage =
            this.route.snapshot.url && this.route.snapshot.url[2] && this.route.snapshot.url[2].path == 'edit' ? true : false;
        const token = this.route.snapshot.paramMap.get('token');
        if (token) {
            this.reservationService.checkUserToken(token).subscribe(reservation => {
                this.reservationRes = reservation;
                this.reservationRes.token = token;
                if (!this.editPage) {
                    if (this.reservationRes &&
                        (this.reservationRes.ticketOrderId ||
                            (this.reservationRes.resPaymentDataTemp &&
                                Object.keys(this.reservationRes.resPaymentDataTemp).length > 0))) {
                        this.fetchPaymentPageDetails();
                    }
                    else {
                        this.showPayment = true;
                        if (this.showPayment) {
                            this.initPayments();
                        }
                        if (this.reservationRes.paymentTemplate && this.reservationRes.paymentId) {
                            this.paymentSuccess = true;
                        }
                    }
                }
                else {
                    if (this.reservationRes) {
                        if ((this.reservationRes.payments && this.reservationRes.payments.status == 'finished') ||
                            this.reservationRes.ticketOrderId) {
                            this.editErrorMsg = this.translateService.instant('widget.reservation.prePaidReservationErrorMsg');
                        }
                        else if (!(this.reservationRes.status == 'pending' ||
                            this.reservationRes.status == 'confirmed' ||
                            this.reservationRes.status == 'waiting' ||
                            this.reservationRes.status == 'blocked')) {
                            this.editErrorMsg = this.translateService.instant('widget.reservation.unableToChangeReservation');
                        }
                        else {
                            this.editErrorMsg = '';
                        }
                        this.guestCount = this.reservationRes.peopleCount;
                        this.selectedDate = moment(this.reservationRes.reservedFor).format('YYYY-MM-DD');
                        this.selectedTime = moment(this.reservationRes.reservedFor).format('HH:mm');
                        // this.selectedStaytime = this.reservationRes.stayTime;
                        // this.selectedRoom = this.reservationRes.roomId;
                        this.getShifts();
                        this.selectedShift = this.reservationRes && this.reservationRes.shift ? this.reservationRes.shift.id : '';
                        this.startReservation();
                        if (this.reservationRes.guestData) {
                            if (this.reservationRes.guestData.phone.indexOf('+') < 0) {
                                this.reservationRes.guestData.phone = `+${this.reservationRes.guestData.phone}`;
                            }
                            this.guestDetailsFormGroup.setValue({
                                name: this.reservationRes.guestData.firstName,
                                surname: this.reservationRes.guestData.name,
                                email: this.reservationRes.guestData.email,
                                phone: this.validatePhoneNo(this.reservationRes.guestData.phone.substring(3)),
                                countryCode: this.reservationRes.guestData.phone.substring(0, 3),
                                addMsg: this.reservationRes.msg,
                                informSms: false,
                                newsletter: false
                            });
                        }
                    }
                }
            }, err => this.router.navigate(['/404']));
        }
    }
    fetchPaymentPageDetails() {
        if (this.reservationRes && this.reservationRes.status == 'confirmed' && this.reservationRes.ticketOrderId) {
            // Fetch payment details
            this.reservationService.getPaymentDetails(this.reservationRes.ticketOrderId, 'ticket').subscribe(payment => {
                this.selectedTicketPrice = true;
                this.paymentId = payment.id;
                this.showPayment = true;
                this.paymentSuccess = true;
                this.getAllTickets();
            });
        }
        else {
            const data = this.reservationRes.resPaymentDataTemp.data;
            // Fix the "optionData" field within the "selectedTicketData" array
            // const fixedData = data.replace(/"optionData":"\[(.*?)\]"/g, '"optionData":[$1]');
            const details = JSON.parse(data);
            this.reservationDataValues = details;
            this.selectedTicketData = details.selectedTicketData;
            if (details.selectedTicketData[0] && details.selectedTicketData[0].optionData) {
                const unescapedOptionDataString = details.selectedTicketData[0].optionData.replace(/\\"/g, '"'); // Remove extra escaping
                this.selectedTicketAddOn = JSON.parse(unescapedOptionDataString);
            }
            else {
                this.selectedTicketAddOn = [];
            }
            this.selectedTicketPrice = details.selectedTicketPrice;
            this.serviceCharge = details.serviceCharge;
            this.finalPriceAfterVoucher = details.finalPriceAfterVoucher;
            this.voucher = details.voucher;
            // if (
            //   this.reservationDataValues &&
            //   this.reservationDataValues.selectedTicketData &&
            //   this.reservationDataValues.selectedTicketData[0].optionData
            // ) {
            //   this.reservationDataValues.selectedTicketData[0].optionData = JSON.stringify(
            //     this.reservationDataValues.selectedTicketData[0].optionData
            //   );
            // }
            if (this.selectedTicketAddOn && this.selectedTicketAddOn.length > 0) {
                this.reservationDataValues.selectedTicketData[0].optionData = JSON.stringify(this.selectedTicketAddOn);
            }
            else {
                this.reservationDataValues.selectedTicketData[0].optionData = null;
            }
            this.showPayment = true;
            if (this.showPayment) {
                this.reservationRes.payment = {
                    price: this.finalPriceAfterVoucher,
                    total: this.finalPriceAfterVoucher
                };
                this.initPayments();
            }
        }
    }
    onChange({ error }) {
        if (error) {
            this.paymentError = error.message;
        }
        else {
            this.paymentError = null;
        }
        this.cd.detectChanges();
    }
    initStripe() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.card) {
                this.card = elements.create('cardNumber', {
                    style: {
                        base: {
                            iconColor: '#666EE8',
                            color: '#31325F',
                            lineHeight: '35px',
                            fontWeight: 400,
                            fontFamily: '"Nunito Sans", Helvetica, sans-serif',
                            fontSize: '16px',
                            '::placeholder': {
                                color: '#CFD7E0'
                            }
                        }
                    }
                });
                this.card.mount(this.cardNumberEl.nativeElement);
            }
            if (!this.cardExpiry) {
                this.cardExpiry = elements.create('cardExpiry', {
                    style: {
                        base: {
                            iconColor: '#666EE8',
                            color: '#31325F',
                            lineHeight: '35px',
                            fontWeight: 400,
                            fontFamily: '"Nunito Sans", Helvetica, sans-serif',
                            fontSize: '16px',
                            '::placeholder': {
                                color: '#CFD7E0'
                            }
                        }
                    }
                });
                this.cardExpiry.mount(this.cardExpiryEl.nativeElement);
            }
            if (!this.cardCVV) {
                this.cardCVV = elements.create('cardCvc', {
                    style: {
                        base: {
                            iconColor: '#666EE8',
                            color: '#31325F',
                            lineHeight: '35px',
                            fontWeight: 400,
                            fontFamily: '"Nunito Sans", Helvetica, sans-serif',
                            fontSize: '16px',
                            '::placeholder': {
                                color: '#CFD7E0'
                            }
                        }
                    }
                });
                this.cardCVV.mount(this.cardCvvEl.nativeElement);
            }
        });
    }
    initPayments() {
        return __awaiter(this, void 0, void 0, function* () {
            this.paymentRequest = yield stripe.paymentRequest({
                country: 'DE',
                currency: 'eur',
                total: {
                    label: this.reservationRes.client.name +
                        ' - ' +
                        this.translateService.instant('widget.reservation.reservationDeposit'),
                    amount: this.reservationRes.payment.total * 100
                },
                requestShipping: false,
                requestPayerName: false,
                requestPayerEmail: false
            });
            if (!this.prButton) {
                this.prButton = elements.create('paymentRequestButton', {
                    paymentRequest: this.paymentRequest
                });
            }
            else {
                this.prButton.update({
                    paymentRequest: this.paymentRequest
                });
            }
            this.paymentRequest.canMakePayment().then((result) => {
                console.log('can make paymewnt', result);
                if (result) {
                    this.nativePayments.applePay = result.applePay ? result.applePay : false;
                    this.nativePayments.googlePay = result.googlePay ? result.googlePay : false;
                    this.prButton.mount(this.prButtonEl.nativeElement);
                }
                else {
                    // document.getElementById('payment-request-button').style.display = 'none';
                }
            });
            this.paymentRequest.on('paymentmethod', (ev) => __awaiter(this, void 0, void 0, function* () {
                const paymentData = {
                    paymentFor: "reservation" /* Reservation */,
                    paymentMethod: "applepay" /* ApplePay */,
                    clientId: this.client.id,
                    id: this.reservationRes.id,
                    reservationData: this.reservationDataValues
                };
                this.paymentService.createPayment(paymentData).subscribe((data) => __awaiter(this, void 0, void 0, function* () {
                    if (data) {
                        // Confirm the PaymentIntent without handling potential next actions (yet).
                        const { error: confirmError } = yield stripe.confirmCardPayment(data.secret, { payment_method: ev.paymentMethod.id }, { handleActions: false });
                        if (confirmError) {
                            // Report to the browser that the payment failed, prompting it to
                            // re-show the payment interface, or show an error message and close
                            // the payment interface.
                            console.log('payment error');
                            this.zone.run(() => (this.paymentError = this.translateService.instant('widget.reservation.paymentNotMade')));
                            ev.complete('fail');
                        }
                        else {
                            // Report to the browser that the confirmation was successful, prompting
                            // it to close the browser payment method collection interface.
                            ev.complete('success');
                            console.log('confirmation success');
                            // Let Stripe.js handle the rest of the payment flow.
                            const { error, paymentIntent } = yield stripe.confirmCardPayment(data.secret);
                            if (error) {
                                // The payment failed -- ask your customer for a new payment method.
                                console.log('payment error');
                                this.zone.run(() => (this.paymentError = this.translateService.instant('widget.reservation.paymentNotMade')));
                            }
                            else {
                                this.paymentId = data.paymentId;
                                this.getAllTickets();
                                // The payment has succeeded.
                                console.log('payment success');
                                this.zone.run(() => (this.paymentSuccess = true));
                            }
                        }
                    }
                    else {
                        ev.complete('fail');
                        console.log('payment error');
                        this.zone.run(() => (this.paymentError = this.translateService.instant('widget.reservation.paymentNotMade')));
                    }
                }), error => {
                    ev.complete('fail');
                    this.paymentError = error.error.msg;
                });
            }));
        });
    }
    initPaypal() {
        this.payPalConfig = {
            currency: 'EUR',
            clientId: 'AejlsIlg_KjKjmLKqxJqFIAwn3ZP02emx41Z2It4IfirQ-nNgZgzWk1CU-Q1QDbYUXjWoYJZ4dq1S2pK',
            advanced: {
                extraQueryParams: [
                    {
                        name: 'disable-funding',
                        value: 'sepa,card,sofort,giropay'
                    }
                ]
            },
            // for creating orders (transactions) on server see
            // https://developer.paypal.com/docs/checkout/reference/server-integration/set-up-transaction/
            createOrderOnServer: data => {
                const paymentData = {
                    paymentFor: "reservation" /* Reservation */,
                    paymentMethod: "paypal" /* Paypal */,
                    name: this.reservationRes && this.reservationRes.guestData
                        ? (this.reservationRes.guestData.firstName ? this.reservationRes.guestData.firstName + ' ' : '') +
                            this.reservationRes.guestData.name
                        : '',
                    clientId: this.client.id,
                    id: this.reservationRes.id,
                    reservationData: this.reservationDataValues
                };
                // return this.paymentService
                //   .createPayment(paymentData)
                //   .toPromise()
                //   .then(res => res)
                //   .then(order => order.orderID);
                return this.paymentService
                    .createPayment(paymentData)
                    .toPromise()
                    .then(res => res)
                    .then(order => {
                    this.paymentId = order.paymentId; // Store the payment ID
                    return order.orderID; // Return the order ID
                });
            },
            onApprove: (data, actions) => {
                this.processingPaypal = true;
                actions.order.get().then((details) => {
                    // console.log('onApprove - you can get full order details inside onApprove: ', details);
                });
            },
            onClientAuthorization: data => {
                this.processingPaypal = false;
                this.getAllTickets();
                this.paymentSuccess = true;
            },
            onCancel: (data, actions) => {
                // this.showCancel = true;
            },
            onError: err => {
                console.log(err);
                this.paymentError = err;
            },
            onClick: (data, actions) => {
                // this.resetStatus();
            }
        };
    }
    ngOnChanges(changes) {
        if (changes.overrideSettings && changes.overrideSettings.currentValue) {
            document.documentElement.style.setProperty('--main-color', changes.overrideSettings.currentValue.mainColor);
            document.documentElement.style.setProperty('--primary', changes.overrideSettings.currentValue.primaryColor);
            document.documentElement.style.setProperty('--background', changes.overrideSettings.currentValue.backgroundColor);
            document.documentElement.style.setProperty('--primary-font', this.overrideSettings.primaryFont);
            document.documentElement.style.setProperty('--secondary-font', this.overrideSettings.secondaryFont);
            document.documentElement.style.setProperty('--alert-color', changes.overrideSettings.currentValue.alertColor);
            document.documentElement.style.setProperty('--form-background', changes.overrideSettings.currentValue.formBackgroundColor
                ? changes.overrideSettings.currentValue.formBackgroundColor
                : changes.overrideSettings.currentValue.backgroundColor);
            // WebFont.load({
            //   google: {
            //     families: [this.overrideSettings.primaryFont, this.overrideSettings.secondaryFont]
            //   }
            // });
            WebFont.load({
                custom: {
                    families: [this.overrideSettings.primaryFont],
                    urls: [`https://fonts.gastroguide.de/${this.overrideSettings.primaryFont}`]
                }
            });
            WebFont.load({
                custom: {
                    families: [this.overrideSettings.secondaryFont],
                    urls: [`https://fonts.gastroguide.de/${this.overrideSettings.secondaryFont}`]
                }
            });
        }
    }
    ngOnDestroy() {
        // let count;
        // this.stateService.apiCount$.subscribe(apiCount => {
        //   console.log('processReservation ===>',apiCount);
        //   count = apiCount;
        // });
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    getClient(callInternalFunction = false) {
        let clientId;
        if (this.widgetClientId) {
            clientId = this.widgetClientId;
        }
        else {
            clientId = this.route.snapshot.paramMap.get('clientId');
        }
        this.clientService
            .getClient(+clientId, true)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((client) => {
            if (!client) {
                this.router.navigate(['/404']);
            }
            this.client = client;
            if (this.iFrameMode) {
                this.loadPixelCode();
            }
            /*
            this.titleService.setTitle(client.name + ' - Tisch reservieren');
            this.metaTagService.updateTag({
              name: 'description',
              content: 'Reservieren Sie jetzt im ' + client.name + ' in ' + client.location
            });
            */
            if (callInternalFunction) {
                this.checkPreSelectedData(this.preSelectedShift);
                this.getCountries();
                this.checkEditOrPaymentPage();
                this.getSettings();
                this.getSmsSettings();
            }
        }, e => this.router.navigate(['/404']));
    }
    setLanguage() {
        this.userLang = this.userLang ? this.userLang : 'de';
        if (this.settings &&
            this.settings.showTranslation &&
            this.settings.defaultLang &&
            this.userLang !== this.settings.defaultLang) {
            this.callGoogleTranslateAPI = true;
            this.storeTranslationLog();
        }
        localStorage.setItem('callGoogleTranslateAPI', JSON.stringify(this.callGoogleTranslateAPI));
        localStorage.setItem('defaultLang', this.settings.defaultLang);
        this.dateAdapter.setLocale(this.userLang);
        if (this.userLang && this.userLang == 'de') {
            if (this.client.pronominalForm === 'sie') {
                this.i18n.language = 'de-Formal';
            }
            else {
                this.i18n.language = 'de-DE';
            }
        }
        else {
            console.log(this.userLang);
            this.i18n.language = this.userLang;
            console.log(this.i18n.language);
        }
        console.log('lang =>', this.settings.defaultLang, this.userLang, this.i18n.language, this.callGoogleTranslateAPI);
    }
    getSettings() {
        this.reservationService
            .getSettingsOfClient(+this.client.id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((settings) => {
            this.settings = settings;
            this.reservationUntil = this.settings.reservationUntil
                ? addDays(new Date(), this.settings.reservationUntil)
                : addDays(new Date(), Constants.maxReservationUntilDays);
            this.version = this.settings.version;
            this.shiftDateSettings();
            this.settings.defaultLang = this.settings.defaultLang ? this.settings.defaultLang : 'de';
            this.userLang = this.userLang ? this.userLang : this.settings.defaultLang;
            this.setLanguage();
        });
    }
    changeDate() {
        this.isDataChanged = true;
        this.stateService.updateState(true);
        this.resetForm();
        this.getShifts();
    }
    changeGuestCount() {
        this.availabilityCheckGuestCount = this.guestCount;
        if (this.selectedShift && this.selectedDate) {
            this.startReservation();
        }
    }
    shiftDateSettings() {
        this.showShiftFirstTemplate = this.settings.showShiftFirstOnline;
        if (this.showShiftFirstTemplate) {
            this.reservationService
                .getOnlineShifts(this.client.id)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((res) => __awaiter(this, void 0, void 0, function* () {
                this.shifts = res;
                this.shifts = this.shifts.filter((item) => item.isOnlineResShift);
            }));
        }
    }
    changeShiftValue() {
        this.ticketData = [];
        this.selectedShiftObject = this.shifts.find((shift) => shift.id === this.selectedShift) || null;
        this.isDataChanged = true;
        this.stateService.updateState(true);
        if (this.showShiftFirstTemplate) {
            this.checkPreSelectedData(this.selectedShift);
        }
        else {
            this.startReservation();
        }
    }
    getShifts() {
        if (!this.showShiftFirstTemplate) {
            this.shifts = null;
            this.selectedShift = null;
        }
        this.reservationService
            .getShiftsOfDate(+this.client.id, moment(this.selectedDate).format('YYYY-MM-DD'), this.userLang)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => __awaiter(this, void 0, void 0, function* () {
            if (!this.showShiftFirstTemplate) {
                this.errorMsg = null;
                this.shifts = res.shifts;
                this.alerts = res.alerts;
                this.openingHoursToday = res.openingHoursToday;
                if (this.preSelectedShift) {
                    yield this.filterShiftsAsync(this.preSelectedShift);
                }
                // If only 1 shift, set that shift as selected
                if (this.shifts && this.shifts.length === 1) {
                    this.selectedShift = this.shifts[0].id;
                    this.selectedShiftObject = this.shifts[0];
                    this.startReservation();
                }
            }
            else {
                if (this.preSelectedShift) {
                    this.shifts = res.shifts;
                    yield this.filterShiftsAsync(this.preSelectedShift);
                    // If only 1 shift, set that shift as selected
                    if (this.shifts && this.shifts.length === 1) {
                        this.selectedShift = this.shifts[0].id;
                        this.startReservation();
                    }
                }
                else {
                    this.errorMsg = null;
                    this.alerts = res.alerts;
                    this.openingHoursToday = res.openingHoursToday;
                    this.startReservation();
                }
            }
        }), error => {
            this.openingHoursToday = null;
            this.errorMsg = error.error.msg;
            this.alerts = error.error && error.error.alerts ? error.error.alerts : [];
            if (!this.showShiftFirstTemplate) {
                this.shifts = [];
            }
        });
    }
    filterShiftsAsync(shiftId) {
        return new Promise((resolve, reject) => {
            // Filter shifts asynchronously
            this.shifts = this.shifts.filter((item) => item.id == shiftId);
            resolve();
        });
    }
    /* Call this only when guestCount, selectedDate, selectedShift is there */
    startReservation() {
        if (!this.guestCount || !this.selectedDate || !this.selectedShift) {
            return;
        }
        this.isLoading = true;
        this.resetForm();
        this.reservationService
            .startReservation(this.client.id, this.guestCount, this.selectedDate, this.selectedShift, this.userLang)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.availableTimes = res.times;
            this.availableTimesAll = res.times;
            this.alternativeTimes = res.times;
            this.availableRooms = res.rooms;
            this.questions = res.questions;
            this.availableTimesMidNight = res.timeMidNight;
            if (res.shift.stayTimeType !== 'static') {
                this.availableStaytime = res.stayTime;
                // Change slider options
                if (this.availableStaytime) {
                    this.stayTimeOptions.floor = this.availableStaytime.minStayTime;
                    this.stayTimeOptions.ceil = this.availableStaytime.maxStayTime;
                }
            }
            this.CompulsoryQuestion = 0;
            this.answerCount = 0;
            this.questionAnswer = [];
            if (this.questions && this.questions.length > 0) {
                this.questions = this.questions.filter((item, key) => {
                    if (item.questionType == 2 && item.foreignId) {
                        this.getResMenuOrder(item, key);
                        return item;
                    }
                    else {
                        if (this.reservationRes && this.reservationRes.id) {
                            if (item.questionType !== 3) {
                                return item;
                            }
                        }
                        else {
                            return item;
                        }
                    }
                });
            }
            this.setFormGroupQuestion(this.questions);
            console.log('SET SHIFT OBJECT');
            this.selectedShiftObject = res.shift;
            // If only 1 Room, select it by default
            if (res.rooms.length === 1) {
                this.selectRoom(res.rooms[0]);
            }
            if (res.stayTime) {
                this.selectedStaytime = res.stayTime.defaultStayTime ? res.stayTime.defaultStayTime : 60;
            }
            this.checkForFreeTables();
            this.getReservationAddOnTemplates();
            this.showMaxGuestWarning();
            this.clearTicketData();
            if (this.selectedShiftObject.id && this.selectedShiftObject.eventId) {
                this.getShiftEventDetails();
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.errorMsg = error.error.msg;
        });
    }
    clearTicketData() {
        this.ticketData = [];
        this.selectedTicket = null;
        this.selectedTicketData = [];
        this.selectedTicketPrice = 0;
        this.moveForward = true;
        this.guestCountArray = [];
        this.ticketHolder = [];
        this.selectedTicketAddOn = [];
        this.selectedTicketAddOnPrice = 0;
        this.selectedTicketSubCategoryPrice = 0;
        this.serviceCharge = 0;
    }
    getShiftEventDetails() {
        this.isLoading = true;
        this.reservationService
            .getShiftEventDetails(this.selectedShiftObject.id, moment(this.selectedDate).format('YYYY-MM-DD'))
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.isLoading = false;
            if (res && res.tickets) {
                this.getTicketSettings();
                this.ticketData = res.tickets;
                if (this.ticketData.length === 1) {
                    this.selectTicket(this.ticketData[0]);
                }
                this.moveForward = false;
            }
        });
    }
    selectTicket(ticket) {
        this.selectedTicket = ticket;
        this.createGuestCountArray();
        this.selectedTicketData = [];
        this.selectedTicketPrice = 0;
        this.moveForward = false;
        this.ticketHolder = [];
        this.selectedTicketAddOn = [];
        this.selectedTicketAddOnPrice = 0;
        this.selectedTicketSubCategoryPrice = 0;
        this.serviceCharge = 0;
    }
    selectTicketCategory(guest, category, ticket) {
        if (this.selectedShiftObject.type === 1 || this.selectedShiftObject.type === 2) {
            this.selectedTime = '';
        }
        this.selectedTicketPrice = 0;
        this.selectedTicketSubCategoryPrice = 0;
        let categoryData = { eventId: ticket.eventId };
        if (this.selectedShiftObject && (this.selectedShiftObject.type === 1 || this.selectedShiftObject.type === 2)) {
            this.selectedTicketData = [];
            for (let i = 0; i < this.guestCount; i++) {
                this.selectedTicketData.push(Object.assign({}, category, categoryData));
            }
        }
        else {
            this.selectedTicketData[guest] = Object.assign({}, category, categoryData);
        }
        for (let ticketData of this.selectedTicketData) {
            this.selectedTicketPrice = parseFloat(this.selectedTicketPrice) + (ticketData ? parseFloat(ticketData.price) : 0);
            this.selectedTicketSubCategoryPrice =
                parseFloat(this.selectedTicketSubCategoryPrice) + (ticketData ? parseFloat(ticketData.price) : 0);
        }
        if (this.selectedShiftObject.type === 1 || this.guestCount == this.selectedTicketData.filter(String).length) {
            if (this.selectedTicketData && this.selectedTicketData.length > 0 && !this.selectedTicketPrice) {
                this.moveForward = false;
            }
            else {
                this.moveForward = true;
            }
        }
        // else {
        //   this.moveForward = true;
        // }
        if (this.selectedShiftObject &&
            (this.selectedShiftObject.type === 1 || this.selectedShiftObject.type === 2) &&
            category.subCategoryDetails &&
            category.subCategoryDetails.stayTime) {
            this.selectedStaytime = category.subCategoryDetails.stayTime;
            this.availabilityCheckGuestCount = this.guestCount * category.subCategoryDetails.groupSize;
            if (this.selectedTicket && this.selectedTicket.category && this.selectedTicket.category.length > 1) {
                this.blockTimeAccordingToStayTime();
            }
        }
    }
    blockTimeAccordingToStayTime() {
        if (!this.openingHoursToday || this.openingHoursToday.length === 0) {
            return null;
        }
        // Object with the highest tTo
        const latestClosingTime = this.openingHoursToday.reduce((latest, current) => {
            return current.tTo > latest.tTo ? current : latest;
        });
        if (latestClosingTime && latestClosingTime.tTo) {
            this.availableTimes = this.filterAvailableTimes(this.availableTimesAll, latestClosingTime.tTo, this.selectedStaytime);
            this.availableTimes = [...this.availableTimes];
            console.log('Available Times:', this.availableTimes);
        }
        else {
            console.log('No valid opening hours found.');
        }
    }
    /**
     * Filters the available times based on the stay time and the latest closing time.
     */
    filterAvailableTimes(timesArray, latestClosingTime, staytime) {
        latestClosingTime = moment(latestClosingTime, 'HH:mm').format('HH:mm');
        const latestEndTime = new Date(`1970-01-01T${latestClosingTime}:00`);
        const stayDuration = staytime * 60000; // Convert stay time to milliseconds
        // Adjust for closing time being on the next day
        if (latestClosingTime <= '03:00') {
            latestEndTime.setDate(latestEndTime.getDate() + 1);
        }
        return timesArray.filter((startTime) => {
            const startDateTime = new Date(`1970-01-01T${startTime}:00`);
            // Adjust for times past midnight
            if (startTime <= '03:00' && startTime >= '00:00') {
                startDateTime.setDate(startDateTime.getDate() + 1); // Move to the next day
            }
            return startDateTime.getTime() + stayDuration <= latestEndTime.getTime();
        });
    }
    selectTicketAddOns(index, count, option) {
        this.selectedTicketAddOnPrice = 0;
        if (count) {
            this.selectedTicketAddOn[index] = {
                optionId: option.id,
                price: option.price,
                count,
                optionAmount: option.price * count,
                name: option.name
            };
        }
        else {
            this.selectedTicketAddOn[index] = null;
        }
        for (let ticketAddOn of this.selectedTicketAddOn) {
            this.selectedTicketAddOnPrice =
                parseFloat(this.selectedTicketAddOnPrice) + (ticketAddOn ? parseFloat(ticketAddOn.optionAmount) : 0);
        }
    }
    // Function to create an array of guest count
    createGuestCountArray() {
        this.guestCountArray = Array(this.guestCount)
            .fill(0)
            .map((x, i) => i);
    }
    showMaxGuestWarning() {
        if (this.selectedShiftObject.groupReservation) {
            if (this.guestCount > this.selectedShiftObject.maxGuests) {
                const findIndex = this.alerts.findIndex((item) => item.maxGuestWarning === true);
                if (findIndex == -1 && this.selectedShiftObject.maxGuestsMsg) {
                    this.alerts.push({ info: this.selectedShiftObject.maxGuestsMsg, maxGuestWarning: true });
                }
            }
            else {
                this.alerts = this.alerts.filter((item) => item.maxGuestWarning !== true);
            }
        }
    }
    getResMenuOrder(menu, key) {
        this.reservationService
            .getResMenuOrder(menu.foreignId, this.selectedDate)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.questions[key].menuOrder = res;
            this.questions[key].foreignId = res.id;
            let addedAmount = 0.0;
            if (Object.keys(res).length > 0 && res.menuOrdersItem && Object.keys(res.menuOrdersItem).length > 0) {
                res.menuOrdersItem.map((item2) => {
                    if (!this.reservationRes ||
                        (this.reservationRes &&
                            Object.keys(this.reservationRes).length > 0 &&
                            this.reservationRes.id &&
                            this.reservationRes.id !== item2.reservationId)) {
                        addedAmount += parseFloat(item2.amount);
                    }
                });
            }
            this.questions[key].remainingAmount = parseFloat(res.totalAmount) - parseFloat(addedAmount);
            return res;
        });
    }
    changeStaytime() {
        this.checkForFreeTables();
    }
    selectTime(time) {
        this.midNightDate = null;
        if (this.availableTimesMidNight &&
            this.availableTimesMidNight.length > 0 &&
            this.availableTimesMidNight.includes(time)) {
            this.midNightDate = addDays(this.selectedDate, 1);
        }
        if (this.version == 1) {
            this.loadingFreeTables = true;
            let slotAvailabilityData = {
                clientId: this.client.id,
                guestCount: this.availabilityCheckGuestCount,
                date: this.selectedDate,
                time: time,
                shiftId: this.selectedShift,
                stayTime: this.selectedStaytime,
                roomId: this.selectedRoom,
                resId: this.reservationRes ? this.reservationRes.id : ''
            };
            this.reservationService
                .checkOnlineSlotAvailability(slotAvailabilityData, this.userLang)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((res) => {
                this.loadingFreeTables = false;
                this.selectedTime = time;
                this.endTime = moment(moment(this.selectedTime, 'HH:mm').add(this.selectedStaytime, 'minutes')).format('HH:mm');
                this.debugInfo = res.tables;
                this.slotCalculationForFollowupShift();
            }, err => {
                this.loadingFreeTables = false;
                this.checkSlotMsg = err.error.msg ? err.error.msg : err.message;
                this.mainSlotError = true;
                this.selectedTime = '';
                this.checkAvailableTimes(false);
            });
        }
        else {
            this.selectedTime = time;
            this.endTime = moment(moment(this.selectedTime, 'HH:mm').add(this.selectedStaytime, 'minutes')).format('HH:mm');
            this.slotCalculationForFollowupShift();
        }
    }
    selectAlternativeTime(time) {
        this.selectedTime = time;
        this.stepper.next();
    }
    selectRoom(room) {
        if (room.alert) {
            this.roomAlert = room.alert;
        }
        else {
            this.roomAlert = null;
        }
        // If this room is already selected, unselect it
        if (this.selectedRoom === room.id) {
            this.selectedRoom = null;
            this.roomAlert = null;
        }
        else {
            this.selectedRoom = room.id;
        }
        if (this.selectedShift && this.selectedShiftObject && this.selectedShiftObject.isAutomatic) {
            this.checkForFreeTables();
        }
    }
    answerQuestion(question, answer, i, j, isSelected = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((!this.reservationRes || !this.reservationRes.id) &&
                question &&
                question.questionType == 3 &&
                question.foreignId) {
                if (answer && answer.foreignValue && answer.foreignValue !== 3 && !isSelected) {
                    this.dataValueFollowupShift = answer.foreignValue;
                    this.followUpShiftData(question.foreignId, answer.foreignValue);
                }
                else {
                    this.clearValuesFollowUpShift();
                }
            }
            if (this.questionAnswer &&
                this.questionAnswer[i] &&
                this.questionAnswer[i][j] !== undefined &&
                isSelected &&
                !question.multiSelect) {
                delete this.questionAnswer[i][j];
                // this.questionAnswer[i][j] = {};
                if (question['required'] % 2 > 0) {
                    this.answerCount -= 1;
                }
            }
            else {
                if (typeof answer != 'object' && answer) {
                    answer = {
                        answer,
                        clientId: question.clientId,
                        createdAt: question.createdAt,
                        questionId: question.id,
                        updatedAt: question.updatedAt
                    };
                }
                if (!this.questionAnswer[i]) {
                    this.questionAnswer[i] = [];
                }
                if (!this.questionAnswer[i][j]) {
                    this.questionAnswer[i][j] = {};
                    if (question['required'] % 2 > 0) {
                        this.answerCount += 1;
                    }
                }
                let finalAnswer = [];
                if (question.multiSelect && this.showMultiSelectOption.includes(question.questionType)) {
                    if (this.questionAnswer[i][j].answer) {
                        if (!(yield this.isObjectInArray(answer, this.questionAnswer[i][j].answer))) {
                            finalAnswer = [...this.questionAnswer[i][j].answer, answer];
                        }
                        else {
                            const ifExist = yield this.findObjectFromArray(answer, this.questionAnswer[i][j].answer);
                            if (ifExist !== -1) {
                                this.questionAnswer[i][j].answer.splice(ifExist, 1);
                            }
                            finalAnswer = this.questionAnswer[i][j].answer;
                        }
                    }
                    else {
                        finalAnswer.push(answer);
                    }
                }
                else {
                    finalAnswer.push(answer);
                }
                if (finalAnswer && finalAnswer.length > 0) {
                    // let questionAnswerArray = finalAnswer.map((item: any) => item.answer);
                    let questionAnswerArray = yield Promise.all(finalAnswer.map((item) => __awaiter(this, void 0, void 0, function* () {
                        return item.answer;
                    })));
                    // Filter out undefined values
                    questionAnswerArray = questionAnswerArray.filter(answer => answer !== undefined);
                    if (questionAnswerArray && questionAnswerArray.length > 0) {
                        answer.answerValue = questionAnswerArray.join(', ');
                    }
                    // let tagIdArray = finalAnswer.map((item: any) => item.tagsId);
                    let tagIdArray = yield Promise.all(finalAnswer
                        .map((item) => item.tagsId)
                        .filter((tagsId) => tagsId !== null && tagsId !== undefined && tagsId !== ''));
                    if (tagIdArray && tagIdArray.length > 0) {
                        answer.tagsIdValue = tagIdArray;
                    }
                }
                this.questionAnswer[i][j] = {
                    question: Object.assign({}, question, answer),
                    answer: finalAnswer || {}
                };
                if (isSelected && question.multiSelect && this.questionAnswer[i][j].answer.length == 0) {
                    delete this.questionAnswer[i][j];
                    // this.questionAnswer[i][j] = {};
                    if (question['required'] % 2 > 0) {
                        this.answerCount -= 1;
                    }
                }
                // question.answer = answer;
            }
        });
    }
    isObjectInArray(obj, array) {
        return __awaiter(this, void 0, void 0, function* () {
            return array.some((item) => JSON.stringify(item) === JSON.stringify(obj));
        });
    }
    findObjectFromArray(obj, array) {
        return __awaiter(this, void 0, void 0, function* () {
            const index = array.findIndex((item) => JSON.stringify(item) === JSON.stringify(obj));
            return index;
        });
    }
    isSelected(answer, i, j) {
        if (this.questionAnswer[i] && this.questionAnswer[i][j] && this.questionAnswer[i][j].answer) {
            return this.questionAnswer[i][j].answer.includes(answer);
        }
    }
    loopQuestions(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            for (let index = 0; index < this.questions.length; index++) {
                yield callback(this.questions[index], index, this.questions);
            }
        });
    }
    showNextStepProcess() {
        if (this.allReservationAddOnTemplates.length > 0) {
            this.showReservationData = false;
            this.selectedAddOns = [];
            this.reservationAddOnTemplates = JSON.parse(JSON.stringify(this.allReservationAddOnTemplates));
            this.reservationAddOnTemplates[0].show = true;
        }
        else {
            this.stepper.selected.completed = true;
            this.stepper.next();
            if (this.settings.securityLevel) {
                this.hasCaptcha = true;
            }
            else {
                this.generateReservationToken();
            }
        }
    }
    checkForFreeTables(goNext = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.version == 1) {
                if (!goNext) {
                    this.selectedTime = null;
                    this.alternativeTimes = this.availableTimes;
                }
                this.checkSlotMsg = '';
            }
            // If room choosing is mandatory but user has not chosen any room
            // (and only when click on the next button)
            if (goNext && !this.selectedRoom && this.settings.roomChoice && this.settings.roomChoiceMandatory) {
                this.translateService
                    .get('widget.reservation.noRoomSelected')
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((res) => {
                    this.errorMsgBottom = res;
                });
                return;
            }
            // Reset question answers
            if (goNext) {
                this.adminNotes = '';
            }
            const waitFor = (ms) => new Promise(r => setTimeout(r, ms));
            let isOk = true;
            const checkQuestions = () => __awaiter(this, void 0, void 0, function* () {
                yield this.loopQuestions((question) => __awaiter(this, void 0, void 0, function* () {
                    // If any mandatory question has no answer
                    // (and only when click on the next button)
                    // if (goNext && !question.hasOwnProperty('answer') && question.required) {
                    //   this.translateService
                    //     .get('widget.reservation.questionUnanswered')
                    //     .takeUntil(this.ngUnsubscribe)
                    //     .subscribe((res: string) => {
                    //       this.errorMsgBottom = res;
                    //     });
                    //   isOk = false;
                    // }
                    if (goNext && this.questions && this.isMandatory) {
                        if (this.questionAnswer.length > 0 && this.answerCount == this.CompulsoryQuestion) {
                            this.questionAnswer.forEach((questions, index) => {
                                const innerResults = questions.filter((element) => {
                                    if (Object.keys(element).length !== 0) {
                                        return true;
                                    }
                                    return false;
                                });
                                innerResults.forEach((question) => {
                                    const isCompulsory = question.question.required % 2 == 0
                                        ? false
                                        : question.question.addForm.length == innerResults.length
                                            ? false
                                            : true;
                                    if ((goNext && isCompulsory) || !question.hasOwnProperty('answer')) {
                                        isOk = this.getUnaswered();
                                    }
                                    else {
                                        this.errorMsgBottom = '';
                                    }
                                });
                            });
                        }
                        else {
                            isOk = this.getUnaswered();
                        }
                    }
                    yield waitFor(50);
                    return;
                }));
            });
            yield checkQuestions();
            if (this.questionAnswer.length > 0) {
                let notes = '';
                let maps = [];
                var question = this.questionAnswer
                    // // .reduce(function(prev: any, next: any) {
                    // //   return prev.concat(next);
                    // // })
                    .filter((value) => Object.keys(value).length !== 0);
                // var question = this.questionAnswer.filter((value: {}[]) => {
                //   // Check if the inner array has an object and that object is not empty
                //   return value.length > 0 && Object.keys(value[0]).length !== 0;
                // });
                // for (let i = 0; i < question.length; i++) {
                //   console.log(question[i])
                //   let item = JSON.stringify(question[i]);
                //   maps[item] = maps[item] + 1 || 1;
                // }
                let key = 'question';
                maps = yield this.findOcc(question, key);
                this.resMenuOrderItems = [];
                maps.forEach((data) => {
                    let note = '';
                    data.forEach((item, index) => {
                        if (index == 0) {
                            note += `${item['question']['name']}: `;
                        }
                        const answerValue = item['question']['answerValue'] &&
                            item['question']['answerValue'] !== 'undefined' &&
                            item['question']['answerValue'] !== undefined
                            ? item['question']['answerValue']
                            : item['question']['answer'];
                        if (item['occurrence'] == 1) {
                            note += `${answerValue}, `;
                        }
                        else if (item['occurrence'] > 1) {
                            note += `${item['occurrence']}x ${answerValue}, `;
                        }
                        if (item['question']['questionType'] == 4) {
                            if (item['question']['tagsIdValue'] && item['question']['tagsIdValue'].length > 0) {
                                this.tags = [...new Set([...this.tags, ...item['question']['tagsIdValue']])];
                            }
                        }
                        if (item['question']['questionType'] == 2 &&
                            item['question']['foreignId'] &&
                            item['question']['foreignValue']) {
                            this.resMenuOrderItems.push({
                                menuOrderId: `${item['question']['foreignId']}`,
                                amount: `${item['question']['foreignValue']}`,
                                answerId: item['question']['answers'].find((itemNew) => itemNew.answer === item['question']['answer']).id
                            });
                        }
                    });
                    notes += note.replace(/,\s*$/, '') + ' - ';
                });
                // note += `${question.question.name} ${index+1}: ${question.answer.answer}, `;
                this.adminNotes = this.adminNotes + notes.replace(/-\s*$/, '');
            }
            if (!isOk) {
                return;
            }
            let orderedAmount = 0.0;
            let addedAmount = 0.0;
            let resMenuError = '';
            if (this.resMenuOrderItems && this.resMenuOrderItems.length > 0) {
                this.questions.map((item) => {
                    addedAmount = 0.0;
                    if (item.menuOrder && Object.keys(item.menuOrder).length > 0) {
                        if (item.menuOrder.menuOrdersItem && Object.keys(item.menuOrder.menuOrdersItem).length > 0) {
                            item.menuOrder.menuOrdersItem.map((item2) => {
                                if (!this.reservationRes ||
                                    (this.reservationRes &&
                                        Object.keys(this.reservationRes).length > 0 &&
                                        this.reservationRes.id &&
                                        this.reservationRes.id !== item2.reservationId)) {
                                    addedAmount += parseFloat(item2.amount);
                                }
                            });
                        }
                        orderedAmount = 0.0;
                        this.resMenuOrderItems.map((item3) => {
                            if (item3.menuOrderId == item.foreignId) {
                                orderedAmount += parseFloat(item3.amount);
                            }
                        });
                        if (orderedAmount > parseFloat(item.menuOrder.totalAmount) - parseFloat(addedAmount)) {
                            resMenuError = this.translateService.instant('widget.reservation.resMenuOrderError', {
                                amount: parseFloat(item.menuOrder.totalAmount) - parseFloat(addedAmount) + ' ' + item.menuOrder.name
                            });
                        }
                    }
                });
            }
            if (resMenuError) {
                this.errorMsgBottom = resMenuError;
                return;
            }
            this.errorMsgBottom = null;
            this.reservationFormGroup.setValue({
                guestCount: this.guestCount,
                date: this.selectedDate,
                time: this.selectedTime,
                stayTime: this.selectedStaytime,
                room: this.selectedRoom
            });
            if (this.selectedTicketData && this.selectedTicketData.length > 0) {
                for (let i = 0; i < this.selectedTicketData.length; i++) {
                    this.selectedTicketData[i].ticketHolderName = this.ticketHolder[i] ? this.ticketHolder[i] : '';
                }
                if (this.selectedTicketAddOn && this.selectedTicketAddOn.length > 0) {
                    this.selectedTicketPrice =
                        parseFloat(this.selectedTicketSubCategoryPrice) + parseFloat(this.selectedTicketAddOnPrice);
                    let optionData = this.selectedTicketAddOn.filter((item) => item);
                    this.selectedTicketData[0].optionData = JSON.stringify(optionData);
                }
                this.serviceCharge = yield this.calculateReFee(this.selectedTicketPrice, this.selectedTicketData.length);
                this.selectedTicketPrice = parseFloat(this.selectedTicketPrice) + parseFloat(this.serviceCharge);
                this.finalPriceAfterVoucher = this.selectedTicketPrice;
            }
            if (this.version == 1) {
                this.loadingFreeTables = false;
                if (goNext) {
                    this.showNextStepProcess();
                }
            }
            else {
                this.checkAvailableTimes(goNext);
                if (goNext && this.selectedTimeFollowUpShift) {
                    this.checkForFreeTablesFollowUpShift();
                }
            }
        });
    }
    checkAvailableTimes(goNext) {
        this.loadingFreeTables = true;
        this.reservationService
            .checkAvailableTimes(this.client.id, this.availabilityCheckGuestCount, this.selectedDate, this.selectedTime, this.selectedShift, this.selectedStaytime, this.selectedRoom, this.reservationRes ? this.reservationRes.id : '')
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.loadingFreeTables = false;
            this.alternativeTimes = res.times;
            this.debugInfo = res.tables;
            this.checkSlotMsg = '';
            if (!this.alternativeTimes.length ||
                (this.selectedTime && !this.alternativeTimes.find((time) => time === this.selectedTime))) {
                this.selectedTime = null;
                if (this.selectedShiftObject && this.selectedShiftObject.noSlotAvailableMsg) {
                    this.errorMsgBottom = this.selectedShiftObject.noSlotAvailableMsg;
                }
                else {
                    this.translateService
                        .get('widget.reservation.noSpaceOptions')
                        .takeUntil(this.ngUnsubscribe)
                        .subscribe((text) => {
                        this.errorMsgBottom = text;
                    });
                }
            }
            else {
                this.slotCalculationForFollowupShift();
                if (goNext) {
                    this.showNextStepProcess();
                }
            }
        }, error => {
            this.loadingFreeTables = false;
        });
    }
    resetForm() {
        this.errorMsg = null;
        this.errorMsgBottom = null;
        this.alternativeTimes = [];
        this.availableTimes = null;
        this.availableRooms = null;
        this.availableStaytime = null;
        this.selectedRoom = null;
        this.selectedTime = null;
        this.selectedStaytime = null;
        this.roomAlert = null;
        this.debugInfo = null;
        this.questions = null;
        this.availableTimesMidNight = null;
        this.endTime = null;
        this.clearValuesFollowUpShift();
    }
    getRoomTooltip(room) {
        if (room.minGuests > this.guestCount) {
            return this.translateService.instant('widget.reservation.minGuestPerTable') + room.minGuests;
        }
        if (room.maxGuests < this.guestCount) {
            return this.translateService.instant('widget.reservation.maxGuestPerTable') + room.maxGuests;
        }
        return '';
    }
    isTimeAvailable(time) {
        if (this.alternativeTimes) {
            if (this.alternativeTimes.find((x) => x === time)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }
    getReferrer() {
        const nVer = navigator.appVersion;
        const nAgt = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = '' + parseFloat(navigator.appVersion);
        let majorVersion = parseInt(navigator.appVersion, 10);
        let nameOffset, verOffset, ix;
        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
            browserName = 'Opera';
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        }
        else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
            browserName = 'Microsoft Internet Explorer';
            fullVersion = nAgt.substring(verOffset + 5);
        }
        else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
            browserName = 'Chrome';
            fullVersion = nAgt.substring(verOffset + 7);
        }
        else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
            browserName = 'Safari';
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        }
        else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
            browserName = 'Firefox';
            fullVersion = nAgt.substring(verOffset + 8);
        }
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(';')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }
        if ((ix = fullVersion.indexOf(' ')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }
        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }
        let device = 'Desktop';
        if (this.deviceService.isMobile() && !this.deviceService.isTablet() && !this.deviceService.isDesktop()) {
            device = 'Mobile';
        }
        if (this.deviceService.isTablet() && !this.deviceService.isMobile() && !this.deviceService.isDesktop()) {
            device = 'Tablet';
        }
        return window.location.hostname + ' | ' + device + ' | ' + browserName + ' (' + majorVersion + ')';
    }
    processReservation() {
        if (!this.guestDetailsFormGroup.valid) {
            return;
        }
        if (this.guestDetailsFormGroup.value.phone) {
            this.guestDetailsFormGroup
                .get('phone')
                .setValue(this.validatePhoneNo(this.guestDetailsFormGroup.value.phone, this.countryCodes));
        }
        // Save user data if he select checkbox to save data in form
        if (this.isUserSavingHisData) {
            localStorage.setItem('orderSavedData', JSON.stringify(this.guestDetailsFormGroup.value));
        }
        else {
            localStorage.removeItem('orderSavedData');
        }
        this.guestDetailsFormGroup.value.phone =
            this.guestDetailsFormGroup.value.countryCode + this.guestDetailsFormGroup.value.phone;
        /* replace value from phone*/
        this.guestDetailsFormGroup.value.phone = this.guestDetailsFormGroup.value.phone.replace('+4949', '+49');
        this.guestDetailsFormGroup.value.phone = this.guestDetailsFormGroup.value.phone.replace('+490', '+49');
        this.isFormSubmitting = true;
        let reservedFor = this.midNightDate
            ? moment(this.midNightDate).format('YYYY-MM-DD')
            : moment(this.selectedDate).format('YYYY-MM-DD');
        reservedFor = moment(reservedFor + ' ' + this.selectedTime).format('YYYY-MM-DD HH:mm');
        const reservationData = {
            clientId: this.client.id,
            reservedFor: reservedFor,
            guestCount: this.availabilityCheckGuestCount,
            stayTime: this.selectedStaytime,
            guestData: this.guestDetailsFormGroup.value,
            time: this.selectedTime,
            roomId: this.selectedRoom,
            shiftId: this.selectedShift,
            notes: this.adminNotes,
            resMenuOrderItems: this.resMenuOrderItems,
            referrer: this.getReferrer(),
            id: this.reservationRes ? this.reservationRes.id : '',
            addOns: this.selectedAddOns.length > 0 ? this.selectedAddOns.map((a) => a.id).join(',') : '',
            selectedTicketData: this.selectedTicketData,
            selectedTicketPrice: this.selectedTicketPrice,
            resStatus: this.selectedShiftObject && this.selectedShiftObject.eventId && this.selectedTicketPrice ? 'blocked' : '',
            serviceCharge: this.serviceCharge,
            finalPriceAfterVoucher: this.finalPriceAfterVoucher,
            voucher: this.voucher,
            tags: this.tags
        };
        this.reservationDataValues = JSON.parse(JSON.stringify(reservationData));
        console.log('reservationData', reservationData);
        this.reservationService
            .processReservation(reservationData, this.userLang)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => __awaiter(this, void 0, void 0, function* () {
            if (res && res.id) {
                if (res.status == 'pending') {
                    document.documentElement.style.setProperty('--status-color', '#ffd454');
                }
                this.reservationRes = res;
                this.isEditable = false;
                this.stepper.selected.completed = true;
                yield this.stepper.next();
                if (this.selectedTicketPrice) {
                    document.documentElement.style.setProperty('--status-color', '#FFFF00');
                    this.showClientConfirmPage = !this.selectedShiftObject.isAutomatic ? true : false;
                    this.reservationRes.payment = {
                        price: this.finalPriceAfterVoucher,
                        total: this.finalPriceAfterVoucher
                    };
                    this.showPayment = !this.iFrameMode && !this.showClientConfirmPage;
                    if (this.showPayment) {
                        this.initPayments();
                    }
                }
                this.isFormSubmitting = false;
                if (!this.selectedTicketPrice) {
                    setTimeout(() => {
                        confetti({
                            particleCount: 100,
                            spread: 70,
                            origin: {
                                y: 0.6
                            }
                        });
                    }, 500);
                }
                /*Follow Up Reservation Create*/
                if (this.selectedTimeFollowUpShift && this.selectedShiftFollowUpShift) {
                    this.processReservationFollowUpShift(reservationData);
                }
            }
            else {
                this.processErrorMsg = this.translateService.instant('widget.reservation.unknownErrorOccured');
                this.isFormSubmitting = false;
            }
        }), err => {
            this.processErrorMsg = err.error.msg ? err.error.msg : err.message;
            this.isFormSubmitting = false;
        });
    }
    onOpen() {
        // Force reposition as a workaround for BUG
        // https://github.com/ng-select/ng-select/issues/1259
        setTimeout(() => {
            const component = this.ngSelectComponent;
            if (component.dropdownPanel) {
                // component.dropdownPanel._updatePosition();
            }
        }, 100);
    }
    goToPay() {
        console.log('redirect');
        // if (
        //   window.location.hostname.includes('gastroguide.de') ||
        //   window.location.hostname.includes('localhost') ||
        //   window.location.hostname.includes('gastro.digital')
        // )
        const paymentURL = this.reservationRes.payment.url
            ? this.reservationRes.payment.url
            : `/payment/${this.reservationRes.token}`;
        if (window.location.hostname.includes('gastroguide.de')) {
            console.log('main');
            this.location.replaceState(this.router.url + paymentURL);
            this.showPayment = true;
            this.initPayments();
        }
        else {
            console.log('else');
            window.open('https://reservierung.gastroguide.de/' +
                this.processName(this.reservationRes.client.name, true) +
                '/' +
                this.client.id +
                paymentURL, '_blank');
        }
    }
    goToEdit() {
        if (this.reservationRes.token) {
            const urlLinK = 'https://reservierung.gastroguide.de/' +
                this.processName(this.reservationRes.client.name, true) +
                '/' +
                this.client.id +
                '/edit/' +
                this.reservationRes.token;
            if (window.location.hostname.includes('reservierung.gastroguide.de')) {
                window.location.replace(urlLinK);
            }
            else {
                console.log('else', urlLinK);
                window.open(urlLinK, '_blank');
            }
        }
    }
    goToOrder(menuItem) {
        if (menuItem) {
            const urlLinK = 'https://order.gastroguide.de/' +
                this.processName(this.reservationRes.client.name, true) +
                '/' +
                this.client.id +
                '/' +
                menuItem +
                '/' +
                this.reservationRes.id;
            window.open(urlLinK, '_blank');
        }
    }
    changePaymentMethod($event) {
        if ($event.value === 'cc') {
            this.initStripe();
        }
        if ($event.value === 'paypal') {
            this.initPaypal();
        }
    }
    payReservation() {
        return __awaiter(this, void 0, void 0, function* () {
            this.payingPromise = new Promise((resolve, reject) => {
                switch (this.paymentMethod) {
                    case 'cc':
                        const paymentData = {
                            paymentFor: "reservation" /* Reservation */,
                            paymentMethod: "creditcard" /* CreditCard */,
                            name: this.stripeData.value.name,
                            id: this.reservationRes.id,
                            clientId: this.client.id,
                            reservationData: this.reservationDataValues
                        };
                        this.paymentService.createPayment(paymentData).subscribe(data => {
                            if (data) {
                                stripe
                                    .confirmCardPayment(data.secret, {
                                    payment_method: {
                                        card: this.card,
                                        billing_details: {
                                            name: this.stripeData.value.name
                                        }
                                    }
                                })
                                    .then((result) => {
                                    resolve();
                                    if (result.error) {
                                        // Show error to your customer (e.g., insufficient funds)
                                        this.paymentError = result.error.message;
                                    }
                                    else {
                                        // The payment has been processed!
                                        if (result.paymentIntent.status === 'succeeded') {
                                            this.paymentId = data.paymentId;
                                            this.getAllTickets();
                                            this.paymentSuccess = true;
                                            // Show a success message to your customer
                                            // There's a risk of the customer closing the window before callback
                                            // execution. Set up a webhook or plugin to listen for the
                                            // payment_intent.succeeded event that handles any business critical
                                            // post-payment actions.
                                        }
                                    }
                                });
                            }
                            else {
                                this.paymentError = this.translateService.instant('widget.reservation.paymentNotMade');
                                resolve();
                            }
                        }, error => {
                            this.paymentError = error.error.msg;
                            resolve();
                        });
                        break;
                    case 'applepay':
                        break;
                    case 'klarna':
                        let klarnaData = {
                            paymentFor: "reservation" /* Reservation */,
                            paymentMethod: "klarna" /* Klarna */,
                            id: this.reservationRes.id,
                            clientId: this.client.id,
                            name: this.reservationRes && this.reservationRes.guestData
                                ? (this.reservationRes.guestData.firstName ? this.reservationRes.guestData.firstName + ' ' : '') +
                                    this.reservationRes.guestData.name
                                : '',
                            reservationData: this.reservationDataValues
                        };
                        const klarnaDetails = Object.assign({}, klarnaData, { successURL: this.router.url.replace(/[#,+()$~.'":*?<>{}]/g, '') });
                        this.paymentService.createPayment(klarnaDetails).subscribe((data) => {
                            if (data) {
                                window.location.href = data.url;
                            }
                            else {
                                this.paymentError = this.translateService.instant('widget.reservation.paymentError');
                            }
                        }, error => {
                            this.paymentError = error.error.msg;
                            resolve();
                        });
                        break;
                    case 'free':
                        let freeData = {
                            paymentFor: "reservation" /* Reservation */,
                            paymentMethod: "free" /* Free */,
                            id: this.reservationRes.id,
                            clientId: this.client.id,
                            name: this.reservationRes && this.reservationRes.guestData
                                ? (this.reservationRes.guestData.firstName ? this.reservationRes.guestData.firstName + ' ' : '') +
                                    this.reservationRes.guestData.name
                                : '',
                            reservationData: this.reservationDataValues
                        };
                        this.paymentService.createPayment(freeData).subscribe((data) => {
                            if (data) {
                                this.paymentId = data.paymentId;
                                this.getAllTickets();
                                this.paymentSuccess = true;
                            }
                            else {
                                this.paymentError = this.translateService.instant('widget.reservation.paymentError');
                            }
                        }, error => {
                            this.paymentError = error.error.msg;
                            resolve();
                        });
                        break;
                    default:
                        break;
                }
            });
        });
    }
    translateStatus(status) {
        switch (status) {
            case 'confirmed':
                return this.translateService.instant('widget.reservation.confirmed');
                break;
            case 'canceled':
                return this.translateService.instant('widget.reservation.canceled');
                break;
            case 'noShow':
                return this.translateService.instant('widget.reservation.noShow');
                break;
            case 'arrived':
                return this.translateService.instant('widget.reservation.arrived');
                break;
            case 'placed':
                return this.translateService.instant('widget.reservation.placed');
                break;
            case 'pending':
                return this.translateService.instant('widget.reservation.pending');
                break;
            case 'waiting':
                return this.translateService.instant('widget.reservation.waiting');
                break;
            case 'finished':
                return this.translateService.instant('widget.reservation.finished');
                break;
            case 'blocked':
                return this.translateService.instant('widget.reservation.pending');
                break;
            default:
                return status;
                break;
        }
    }
    setFormGroupQuestion(questions) {
        questions.forEach((question, i) => {
            if (question.required == 0) {
                this.addDescription(1, i);
            }
            else if (question.required == 1) {
                this.addDescription(1, i);
                this.isMandatory = true;
                this.CompulsoryQuestion += 1;
            }
            else if (question.required == 2) {
                this.addDescription(this.guestCount, i);
            }
            else if (question.required == 3) {
                this.addDescription(this.guestCount, i);
                this.isMandatory = true;
                this.CompulsoryQuestion += this.guestCount;
            }
        });
    }
    addDescription(count, index) {
        let questionArray = this.questionsForm.get('description');
        questionArray.clear();
        for (let i = 0; i < count; i++) {
            const add = this.questionsForm.get('description');
            add.push(this.formBuilder.group({
                answer: []
            }));
        }
        let addForm = [];
        addForm = this.questionsForm.get('description')['controls'];
        this.questions[index].addForm = [...addForm];
    }
    getUnaswered() {
        this.translateService
            .get('widget.reservation.questionUnanswered')
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.errorMsgBottom = res;
        });
        return false;
    }
    findOcc(arr, key) {
        let arrBase = [];
        arr.forEach((arr1) => {
            let arr2 = [];
            arr1.forEach((x) => {
                // Checking if there is any object in arr2
                // which contains the key value
                if (arr2.some(val => {
                    return (this.compareCommaSeparatedStrings(val[key].answerValue, x[key].answerValue) &&
                        val[key].name === x[key].name &&
                        val[key].question === x[key].question &&
                        this.showMultiSelectOption.includes(x[key].questionType));
                })) {
                    // If yes! then increase the occurrence by 1
                    arr2.forEach(k => {
                        if (this.compareCommaSeparatedStrings(k[key].answerValue, x[key].answerValue) &&
                            k[key].name === x[key].name &&
                            k[key].question === x[key].question) {
                            k['occurrence']++;
                        }
                    });
                }
                else {
                    // If not! Then create a new object initialize
                    // it with the present iteration key's value and
                    // set the occurrence to 1
                    let a = {};
                    a[key] = x[key];
                    a['occurrence'] = 1;
                    arr2.push(a);
                }
            });
            arrBase.push(arr2);
        });
        return arrBase;
    }
    // Function to check if all values in one comma-separated string are present in another
    compareCommaSeparatedStrings(string1, string2) {
        const array1 = string1.split(',').map((item) => item.trim());
        const array2 = string2.split(',').map((item) => item.trim());
        const allInArray2 = array1.every((value) => array2.includes(value));
        const allInArray1 = array2.every((value) => array1.includes(value));
        return allInArray1 && allInArray2;
    }
    // getQuestionFormArray(count:number){
    //   for (let i = 0; i < count; i++) {
    //     this.questionsForm.push(
    //       new FormGroup({
    //         description: new FormControl('', [Validators.required])
    //       })
    //     );
    //   }
    // }
    getReservationAddOnTemplates() {
        this.reservationService
            .getClientReservationAddOnTemplates(this.client.id, this.selectedShift)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((reservationAddOnTemplates) => {
            this.allReservationAddOnTemplates = reservationAddOnTemplates.map((addon) => {
                addon.price = '0.00';
                addon.addOnsSettings.map((item) => {
                    if (this.guestCount >= item.guestCountFrom && this.guestCount <= item.guestCountTo) {
                        addon.price = item.price;
                    }
                });
                addon.show = false;
                return addon;
            });
            this.selectedAddOns = [];
        });
    }
    selectAddOn(addOn, index) {
        this.selectedAddOns.push({ id: addOn.id, title: addOn.title, price: addOn.price });
        this.nextAddOn(addOn, index);
    }
    nextAddOn(addOn, index) {
        if (typeof this.reservationAddOnTemplates[index + 1] !== 'undefined') {
            this.reservationAddOnTemplates[index].show = false;
            this.reservationAddOnTemplates[index + 1].show = true;
        }
        else {
            this.stepper.selected.completed = true;
            this.stepper.next();
            if (this.settings.securityLevel) {
                this.hasCaptcha = true;
            }
            else {
                this.generateReservationToken();
            }
        }
    }
    showReservationPage() {
        this.stepper.selectionChange.pipe(pluck('selectedIndex')).subscribe((res) => {
            if (res == 0) {
                this.showReservationData = true;
                this.reservationAddOnTemplates = [];
            }
        });
    }
    // Switch Language
    changeLang(event) {
        // Condition to handle the state if value is changes it reset the value, if only lang is change the state will be false.
        if (this.isDataChanged === true) {
            this.stateService.updateState(true);
        }
        else {
            this.stateService.updateState(false);
        }
        this.userLang = event;
        this.setLanguage();
        this.getClient(false);
    }
    getCountries() {
        let countryData = [];
        // this.countries.push({ id: 1000, name: 'Select', phoneCode: '', countryLabel: 'Select' });
        this.reservationService.getGeoCountries().subscribe(countries => {
            countries.sort((a, b) => parseFloat(a.phoneCode) - parseFloat(b.phoneCode));
            countryData = countries.map((data) => {
                data.phoneCode = `+${data.phoneCode}`;
                data.countryLabel = `${data.phoneCode} (${data.name})`;
                this.countryCodes.push(data.phoneCode);
                return data;
            });
            let mainCountries = [];
            countryData.map((value, key) => {
                if (value.phoneCode === '+49' || value.phoneCode === '+43' || value.phoneCode === '+41') {
                    mainCountries.push(value);
                    delete countryData[key];
                }
            });
            if (mainCountries && mainCountries.length > 0) {
                mainCountries.sort((a, b) => parseFloat(b.phoneCode) - parseFloat(a.phoneCode));
            }
            this.countries = [...mainCountries, ...countryData.filter(Boolean)];
            if (this.guestDetailsFormGroup.value.phone) {
                this.guestDetailsFormGroup.get('phone').setValue(this.validatePhoneNo(this.savedData.phone, this.countryCodes));
            }
            if (this.client && this.client.countryId) {
                const correctCountryCode = this.countries.find((x) => x.id === this.client.countryId);
                if (correctCountryCode && correctCountryCode.phoneCode && !this.guestDetailsFormGroup.value.countryCode)
                    this.guestDetailsFormGroup.get('countryCode').patchValue(correctCountryCode.phoneCode);
            }
            else {
                this.guestDetailsFormGroup.get('countryCode').patchValue('+49');
            }
        });
    }
    getSmsSettings() {
        this.reservationService
            .getSmsSettingsOfClient(this.client.id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((smsSettings) => {
            this.smsSettings = smsSettings;
        });
    }
    /* Follow Up reservation Calculation*/
    followUpShiftData(shiftId, dataValueFollowupShift) {
        this.selectedShiftFollowUpShift = shiftId;
        this.reservationService
            .startReservation(this.client.id, this.guestCount, this.selectedDate, this.selectedShiftFollowUpShift, this.userLang)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.errorMsgFollowUpShift = null;
            if (res.times.length == 0) {
                this.translateService
                    .get('widget.reservation.noSpaceOptions')
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((text) => {
                    this.errorMsgFollowUpShift = text;
                });
                return;
            }
            this.availableTimesFollowUpShift = res.times;
            this.alternativeTimesFollowUpShift = res.times;
            this.availableRoomsFollowUpShift = res.rooms;
            this.selectedShiftObjectFollowUpShift = res.shift;
            if (res.shift.stayTimeType !== 'static') {
                this.availableStaytimeFollowUpShift = res.stayTime;
                // Change slider options
                if (this.availableStaytimeFollowUpShift) {
                    this.stayTimeOptionsFollowUpShift.floor = this.availableStaytimeFollowUpShift.minStayTime;
                    this.stayTimeOptionsFollowUpShift.ceil = this.availableStaytimeFollowUpShift.maxStayTime;
                }
            }
            // If only 1 Room, select it by default
            if (res.rooms.length === 1) {
                this.selectRoomFollowUpShift(res.rooms[0]);
            }
            if (res.stayTime) {
                this.selectedStaytimeFollowUpShift = res.stayTime.defaultStayTime ? res.stayTime.defaultStayTime : 60;
            }
            this.slotCalculationForFollowupShift();
            this.checkForFreeTablesFollowUpShift();
        }, error => {
            this.errorMsgFollowUpShift = error.error.msg;
        });
    }
    selectRoomFollowUpShift(room) {
        if (room.alert) {
            this.roomAlertFollowUpShift = room.alert;
        }
        else {
            this.roomAlertFollowUpShift = null;
        }
        // If this room is already selected, unselect it
        if (this.selectedRoomFollowUpShift === room.id) {
            this.selectedRoomFollowUpShift = null;
            this.roomAlertFollowUpShift = null;
        }
        else {
            this.selectedRoomFollowUpShift = room.id;
        }
        if (this.selectedShiftFollowUpShift &&
            this.selectedShiftObjectFollowUpShift &&
            this.selectedShiftObjectFollowUpShift.isAutomatic &&
            this.selectedStaytimeFollowUpShift) {
            this.checkForFreeTablesFollowUpShift();
        }
    }
    selectTimeFollowUpShift(time) {
        if (this.version == 1) {
            this.loadingFreeTablesFollowUpShift = true;
            let slotAvailabilityData = {
                clientId: this.client.id,
                guestCount: this.guestCount,
                date: this.selectedDate,
                time: time,
                shiftId: this.selectedShiftFollowUpShift,
                stayTime: this.selectedStaytimeFollowUpShift,
                roomId: this.selectedRoomFollowUpShift,
                resId: this.reservationRes ? this.reservationRes.id : '',
                additionalRes: true
            };
            this.reservationService
                .checkOnlineSlotAvailability(slotAvailabilityData, this.userLang)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((res) => {
                this.loadingFreeTablesFollowUpShift = false;
                this.selectedTimeFollowUpShift = time;
                this.disableNextButton = false;
                this.showResSummary();
                // this.debugInfo = res.tables;
            }, err => {
                this.loadingFreeTablesFollowUpShift = false;
                this.checkSlotMsgFollowUpShift = err.error.msg ? err.error.msg : err.message;
                this.followUpSlotError = true;
                this.selectedTimeFollowUpShift = '';
                this.disableNextButton = true;
                this.checkAvailableTimesFollowUpShift();
            });
        }
        else {
            this.selectedTimeFollowUpShift = time;
            this.disableNextButton = false;
            this.showResSummary();
        }
    }
    isTimeAvailableFollowUpShift(time) {
        if (this.alternativeTimesFollowUpShift) {
            if (this.alternativeTimesFollowUpShift.find((x) => x === time)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }
    checkForFreeTablesFollowUpShift() {
        return __awaiter(this, void 0, void 0, function* () {
            this.errorMsgBottomFollowUpShift = null;
            if (this.version == 1) {
                this.loadingFreeTablesFollowUpShift = false;
                this.selectedTimeFollowUpShift = null;
                this.alternativeTimesFollowUpShift = this.availableTimesFollowUpShift;
                this.disableNextButton = true;
            }
            else {
                this.checkAvailableTimesFollowUpShift();
            }
        });
    }
    checkAvailableTimesFollowUpShift() {
        this.loadingFreeTablesFollowUpShift = true;
        this.reservationService
            .checkAvailableTimes(this.client.id, this.availabilityCheckGuestCount, this.selectedDate, this.selectedTimeFollowUpShift, this.selectedShiftFollowUpShift, this.selectedStaytimeFollowUpShift, this.selectedRoomFollowUpShift, this.reservationRes ? this.reservationRes.id : '', true)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.loadingFreeTablesFollowUpShift = false;
            this.alternativeTimesFollowUpShift = res.times;
            this.checkSlotMsgFollowUpShift = '';
            if (!this.alternativeTimesFollowUpShift.length ||
                (this.selectedTimeFollowUpShift &&
                    !this.alternativeTimesFollowUpShift.find((time) => time === this.selectedTimeFollowUpShift))) {
                this.selectedTimeFollowUpShift = null;
                this.translateService
                    .get('widget.reservation.noSpaceOptions')
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((text) => {
                    this.errorMsgBottomFollowUpShift = text;
                });
            }
            else {
                this.showResSummary();
            }
        }, error => {
            this.loadingFreeTablesFollowUpShift = false;
        });
    }
    processReservationFollowUpShift(reservationData) {
        let reservedFor = moment(this.selectedDate).format('YYYY-MM-DD');
        reservedFor = moment(reservedFor + ' ' + this.selectedTimeFollowUpShift).format('YYYY-MM-DD HH:mm');
        reservationData.stayTime = this.selectedStaytimeFollowUpShift;
        reservationData.time = this.selectedTimeFollowUpShift;
        reservationData.roomId = this.selectedRoomFollowUpShift;
        reservationData.shiftId = this.selectedShiftFollowUpShift;
        reservationData.reservedFor = reservedFor;
        reservationData.notes = null;
        reservationData.followUpRes = true;
        (reservationData.resStatus =
            this.selectedShiftObjectFollowUpShift && this.selectedShiftObjectFollowUpShift.eventId && this.selectedTicketPrice
                ? 'blocked'
                : ''),
            this.reservationService
                .processReservation(reservationData, this.userLang)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((res) => __awaiter(this, void 0, void 0, function* () {
                if (res && res.id) {
                    console.log('Follow Up Reservation created successfully');
                }
                else {
                    this.processErrorMsg = this.translateService.instant('widget.reservation.unknownErrorOccured');
                }
            }), err => {
                this.processErrorMsg = err.error.msg ? err.error.msg : err.message;
            });
    }
    clearValuesFollowUpShift() {
        this.selectedShiftFollowUpShift = null;
        this.checkSlotMsgFollowUpShift = null;
        this.loadingFreeTablesFollowUpShift = false;
        this.availableRoomsFollowUpShift = null;
        this.availableTimesFollowUpShift = null;
        this.alternativeTimesFollowUpShift = null;
        this.selectedStaytimeFollowUpShift = null;
        this.errorMsgFollowUpShift = null;
        this.roomAlertFollowUpShift = null;
        this.selectedRoomFollowUpShift = null;
        this.selectedTimeFollowUpShift = null;
        this.errorMsgBottomFollowUpShift = null;
        this.selectedShiftObjectFollowUpShift = null;
        this.shiftOverlapErrorMsg = null;
        this.disableNextButton = false;
        this.mainSlotError = false;
        this.followUpSlotError = false;
        this.availableStaytimeFollowUpShift = null;
    }
    slotCalculationForFollowupShift() {
        this.showResSummary();
        if (this.availableTimesFollowUpShift && this.availableTimesFollowUpShift.length > 0) {
            if (this.dataValueFollowupShift == 1 && this.selectedTime && this.selectedStaytimeFollowUpShift) {
                this.lastSlotFollowUpShift = moment(this.selectedTime, 'HH:mm').subtract(this.selectedStaytimeFollowUpShift, 'minutes');
                this.firstSlotFollowUpShift = moment(this.lastSlotFollowUpShift, 'HH:mm').subtract(60, 'minutes');
            }
            else if (this.dataValueFollowupShift == 2 && this.selectedTime && this.selectedStaytime) {
                this.firstSlotFollowUpShift = moment(this.selectedTime, 'HH:mm').add(this.selectedStaytime, 'minutes');
                this.lastSlotFollowUpShift = moment(this.firstSlotFollowUpShift, 'HH:mm').add(60, 'minutes');
            }
            this.firstSlotFollowUpShift = moment(this.firstSlotFollowUpShift).format('HH:mm');
            this.lastSlotFollowUpShift = moment(this.lastSlotFollowUpShift).format('HH:mm');
        }
    }
    showResSummary() {
        this.showSummaryNote =
            '<p>' +
                this.translateService.instant('widget.reservation.BookedSlots') +
                ' :' +
                '<br>' +
                (this.selectedShiftObject && this.selectedShiftObject.name && this.selectedTime
                    ? (this.selectedShiftObject.name || '') +
                        ' : ' +
                        this.selectedTime +
                        '-' +
                        moment(moment(this.selectedTime, 'HH:mm').add(this.selectedStaytime, 'minutes')).format('HH:mm')
                    : '') +
                '<br>' +
                (this.selectedShiftObjectFollowUpShift &&
                    this.selectedShiftObjectFollowUpShift.name &&
                    this.selectedTimeFollowUpShift
                    ? (this.selectedShiftObjectFollowUpShift.name || '') +
                        ' : ' +
                        this.selectedTimeFollowUpShift +
                        '-' +
                        moment(moment(this.selectedTimeFollowUpShift, 'HH:mm').add(this.selectedStaytimeFollowUpShift, 'minutes')).format('HH:mm')
                    : '') +
                '</p>';
        if (this.selectedTime && this.selectedTimeFollowUpShift) {
            const e1start = this.selectedTime;
            const e1end = moment(moment(this.selectedTime, 'HH:mm').add(this.selectedStaytime, 'minutes')).format('HH:mm');
            const e2start = this.selectedTimeFollowUpShift;
            const e2end = moment(moment(this.selectedTimeFollowUpShift, 'HH:mm').add(this.selectedStaytimeFollowUpShift, 'minutes')).format('HH:mm');
            this.shiftOverlapErrorMsg =
                (e1start > e2start && e1start < e2end) ||
                    (e2start > e1start && e2start < e1end) ||
                    e1start == e2start ||
                    e1end == e2end;
        }
        else {
            this.shiftOverlapErrorMsg = false;
        }
    }
    getAllTickets() {
        if (this.paymentId && this.selectedTicketPrice) {
            this.allTickets = [];
            const startTime = Date.now();
            let remainingTime = 60; // Total time in seconds
            this.hasTimedOut = false; // Flag to check if timeout has already been handled
            // Function to fetch tickets with retry logic
            const fetchTickets = () => {
                this.reservationService
                    .getTicketsForPayment(this.paymentId)
                    .pipe(retryWhen(errors => errors.pipe(delay(20000), // 20-second delay between retries
                take(3))), take(1))
                    .subscribe((response) => {
                    this.allTickets = response.tickets;
                    // Optionally download the tickets
                    // this.downloadTicketAll(this.allTickets);
                }, (error) => {
                    console.error('Error fetching tickets for paymentId:', this.paymentId, error);
                });
            };
            // Function to handle timeout
            const handleTimeout = () => {
                if (!this.hasTimedOut) {
                    console.warn('Ticket fetching process has been terminated after 1 minute.');
                    this.hasTimedOut = true;
                    // Additional logic to handle timeout can be added here if necessary
                }
            };
            // Function to update the timer display
            const updateTimer = () => {
                const minutes = Math.floor(remainingTime / 60);
                const seconds = remainingTime % 60;
                const minutesElement = document.getElementById('minutes');
                const secondsElement = document.getElementById('seconds');
                if (minutesElement && secondsElement) {
                    minutesElement.textContent = minutes.toString().padStart(2, '0');
                    secondsElement.textContent = seconds.toString().padStart(2, '0');
                }
            };
            // Start the countdown timer
            updateTimer(); // Initial update
            const intervalId = setInterval(() => {
                if (remainingTime > 0) {
                    remainingTime -= 1;
                    updateTimer();
                }
                else {
                    clearInterval(intervalId);
                    handleTimeout(); // Handle timeout logic when countdown finishes
                }
            }, 1000); // Update every 1 second
            // Start the fetch process
            fetchTickets();
            // Periodically check if the process has completed within 1 minute
            const checkInterval = setInterval(() => {
                if (Date.now() - startTime >= 60000) {
                    // 1 minute
                    clearInterval(checkInterval);
                    handleTimeout(); // Ensure timeout logic is handled if time has passed
                }
            }, 5000); // Check every 5 seconds
        }
    }
    downloadTicket(ticket) {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        const blobdata = new Blob([new Uint8Array(ticket.fileData.data)], { type: 'application/pdf' });
        link.href = URL.createObjectURL(blobdata);
        link.download = ticket.filename;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
    }
    downloadTicketAll(tickets) {
        if (tickets && tickets.length) {
            for (let i = 0; i < tickets.length; i++) {
                this.downloadTicket(tickets[i]);
            }
        }
    }
    formatDate(date) {
        date = new Date(date);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date
            .getDate()
            .toString()
            .padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    getTicketSettings() {
        this.reservationService
            .getTicketSettings(this.client.id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.generalTicketSettings = res;
        });
    }
    calculateReFee(ticketPrice, ticketCount) {
        let paymentFee = '0.00';
        let paymentFeeVAT = '0.00';
        let finalPaymentFee = '0.00';
        let serviceCharge = '0.00';
        let finalCharge = '0.00';
        if (this.selectedTicket && this.selectedTicket.feeTakeover && this.selectedTicket.feeTakeover != '0') {
            paymentFee = ((+ticketPrice * +this.generalTicketSettings.provision) / 100 +
                +this.generalTicketSettings.transactionFee).toFixed(2);
            if (this.generalTicketSettings.VAT) {
                paymentFeeVAT = ((parseFloat(paymentFee) * 100 * this.generalTicketSettings.VAT) / 10000).toFixed(2);
            }
            finalPaymentFee = (parseFloat(paymentFee) + parseFloat(paymentFeeVAT)).toFixed(2);
        }
        if (this.selectedTicket.serviceCharge) {
            serviceCharge = (parseFloat(this.selectedTicket.serviceCharge) * ticketCount).toFixed(2);
        }
        finalCharge = (parseFloat(finalPaymentFee) + parseFloat(serviceCharge)).toFixed(2);
        console.log('service charge', paymentFee, paymentFeeVAT, finalPaymentFee, serviceCharge, finalCharge);
        return finalCharge;
    }
    checkVoucher() {
        this.reservationService.checkVoucher(this.client.id, this.enteredVoucher).subscribe(res => {
            this.voucherError = null;
            this.voucher = res;
            this.setVoucherValue(this.voucher);
        }, error => {
            console.log('err', error);
            this.voucherError = error.error.msg;
        });
    }
    removeVoucher() {
        this.enteredVoucher = '';
        this.voucher = {};
        this.voucherError = null;
        this.setVoucherValue(null);
    }
    setVoucherValue(voucher) {
        this.showBuyFreeButton = false;
        this.paymentMethod = '';
        if (voucher && voucher.residualValue > 0) {
            this.voucher.voucherValue =
                this.selectedTicketPrice - voucher.residualValue < 0 ? this.selectedTicketPrice : voucher.residualValue;
            this.finalPriceAfterVoucher =
                this.selectedTicketPrice - voucher.residualValue < 0 ? 0 : this.selectedTicketPrice - voucher.residualValue;
        }
        else {
            this.voucher.voucherValue = 0;
            this.finalPriceAfterVoucher = this.selectedTicketPrice;
            this.voucherError = voucher ? this.translateService.instant('widget.reservation.voucherCodeInvalid') : '';
        }
        if (!this.finalPriceAfterVoucher) {
            this.showBuyFreeButton = true;
            this.paymentMethod = 'free';
            // this.voucher.voucherValue = 0;
            // this.voucherError = this.translateService.instant('widget.reservation.totalAmountNotZero');
            // return;
        }
        this.reservationRes.payment = { price: this.finalPriceAfterVoucher, total: this.finalPriceAfterVoucher };
        this.reservationDataValues.serviceCharge = this.serviceCharge;
        this.reservationDataValues.finalPriceAfterVoucher = this.finalPriceAfterVoucher;
        this.reservationDataValues.voucher = this.voucher;
        if (!this.showBuyFreeButton) {
            this.initPayments();
        }
    }
    changeStaytimeFollowUpShift() {
        this.checkForFreeTablesFollowUpShift();
    }
    processName(name, removeSlashAndDot = false) {
        const specialCharactersPipe = new SpecialCharactersPipe();
        return specialCharactersPipe.transform(name, removeSlashAndDot);
    }
    onCaptchaTokenReceived(token) {
        // Automatically receive the token from reCAPTCHA
        this.captchaToken = token;
        this.generateReservationToken();
    }
    generateReservationToken() {
        this.reservationService
            .generateReservationToken(this.client.id, this.captchaToken)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.reservationToken = res;
            localStorage.setItem('reservationToken', res);
        }, err => {
            console.log('error');
        });
    }
    sendPaymentLinkViaEmail() {
        const baseURL = `https://reservierung.gastroguide.de/${this.processName(this.reservationRes.client.name, true)}/${this.reservationRes.betriebId}`;
        this.reservationService
            .sendPaymentLinkViaEmail(baseURL, this.reservationRes.id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((res) => {
            this.snackBar.open(this.translateService.instant('widget.reservation.emailSentSuccessfully'), '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
        }, err => {
            console.log('error');
        });
    }
    loadPixelCode() {
        if (this.client && this.client.clientSettings) {
            if (this.client.clientSettings.metaPixelCode) {
                HelpersService.loadFacebookAnalytics(this.client.clientSettings.metaPixelCode);
            }
            if (this.client.clientSettings.googlePixelCode) {
                HelpersService.loadGoogleAnalytics(this.client.clientSettings.googlePixelCode);
            }
        }
    }
}
